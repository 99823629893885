import { Modal } from 'antd';
import { ExistingPayrollTableContainer } from './ExistingPayrollTableContainer';
import { NewPayrollTable } from './NewPayrollTable';

function PayrollModal(props) {
  return (
    <Modal
      title={'Planilla'}
      visible={true}
      onCancel={props.onCancel}
      footer={null}
      width={'95%'}
    >
      {props.payrollModalContent == 'old' ? (
        <ExistingPayrollTableContainer
          API_domain={props.API_domain}
          selectedPayroll={props.selectedPayroll}
          selectedAccountingClientId={props.selectedAccountingClientId}
          sendApprovedPayroll={props.sendApprovedPayroll}
          setSendApprovedPayroll={props.setSendApprovedPayroll}
          onCancel={props.onCancel}
        />
      ) : (
        <NewPayrollTable
          API_domain={props.API_domain}
          selectedAccountingClientId={props.selectedAccountingClientId}
          setSelectedPayroll={props.setSelectedPayroll}
          onCancel={props.onCancel}
          setPayrollModalContent={props.setPayrollModalContent}
          setSendApprovedPayroll={props.setSendApprovedPayroll}
        />
      )}
    </Modal>
  );
}

export { PayrollModal };
