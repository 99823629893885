import { Affix, Col, Layout, Row, Tag } from 'antd';
import { useState } from 'react';
import styled from 'styled-components/macro';
import { TeamSelect } from './TeamSelect.js';
import { NewReportForm } from './NewReportForm.js';
import { Refresh } from './Refresh.js';
import { ReportSingleModal } from './ReportSingleModal.js';
import { ReportsTable } from './ReportsTable.js';
import { ReportsMyReportsButton } from './ReportsMyReportsButton.js';

const { Content } = Layout;

function ReportsView(props) {
  const [submittedToggle, setSubmittedToggle] = useState(false);
  const [selectedReport, setSelectedReport] = useState();
  const [isLoaded, setLoaded] = useState(false);
  const [highlightedColor, setHighilightedColor] = useState('transparent');
  const [filterPendingUserApproval, setFilterPendingUserApproval] =
    useState(false);

  const onSubmit = () => {
    setSubmittedToggle(!submittedToggle);
  };
  return (
    <Content
      style={{
        margin: '0 0 0',
        overflow: 'initial',
        borderLeft: 'solid rgb(235,235,235) 1px',
        borderRight: 'solid rgb(235,235,235) 1px',
        borderTop: 'solid rgb(235,235,235) 1px',
      }}
    >
      {props.mobile ? (
        <Affix>
          <AffixDiv>
            <ButtonDiv>
              <NewReportForm
                API_domain={props.API_domain}
                onSubmit={onSubmit}
                submittedToggle={submittedToggle}
                team={props.team}
                mobile={props.mobile}
              />
            </ButtonDiv>
          </AffixDiv>
        </Affix>
      ) : (
        <Affix>
          <AffixDiv>
            {/* <Tag color='red'>
              BOT DE WHATSAPP ESTÁ MALFUNCIONANDO TEMPORALMENTE
            </Tag> */}
            <Row>
              <Col span={12} style={{ textAlign: 'left' }}>
                <Refresh onClick={onSubmit} spin={!isLoaded} />
                <TeamSelect
                  API_domain={props.API_domain}
                  onTeamSelect={props.onTeamSelect}
                  team={props.team}
                  highlightedColor={highlightedColor}
                />
              </Col>
              <Col span={9} style={{ textAlign: 'right' }}>
                <ReportsMyReportsButton
                  API_domain={props.API_domain}
                  team={props.team}
                  onClick={() => setFilterPendingUserApproval(true)}
                  submittedToggle={submittedToggle}
                />
              </Col>
              <Col span={3} style={{ textAlign: 'right' }}>
                <ButtonDiv
                  onMouseEnter={() => {
                    if (props.team == 'all' || !props.team) {
                      setHighilightedColor('red');
                    }
                  }}
                  onMouseLeave={() => {
                    setHighilightedColor('transparent');
                  }}
                >
                  <NewReportForm
                    API_domain={props.API_domain}
                    onSubmit={onSubmit}
                    submittedToggle={submittedToggle}
                    team={props.team}
                  />
                </ButtonDiv>
              </Col>
            </Row>
          </AffixDiv>
        </Affix>
      )}
      {selectedReport && (
        <ReportSingleModal
          report_id={selectedReport.id}
          report_comments={selectedReport.comments}
          report_title={selectedReport.title}
          API_domain={props.API_domain}
          onSubmit={onSubmit}
          handleCancel={() => setSelectedReport(null)}
          handleOk={() => setSelectedReport(null)}
          team={selectedReport.team}
          mobile={props.mobile}
        />
      )}

      <div style={{ maxWidth: 1200, margin: 'auto' }}>
        <div
          style={{
            textAlign: 'left',
            paddingLeft: 25,
            fontSize: 36,
            fontWeight: 'bold',
          }}
        >
          Reportes
        </div>
        <ReportsTable
          API_domain={props.API_domain}
          submittedToggle={submittedToggle}
          onSubmit={onSubmit}
          openModal={setSelectedReport}
          team={props.team}
          mobile={props.mobile}
          isLoaded={isLoaded}
          setLoaded={setLoaded}
          filterPendingUserApproval={filterPendingUserApproval}
          setFilterPendingUserApproval={setFilterPendingUserApproval}
        />
      </div>
    </Content>
  );
}

const AffixDiv = styled.div`
  background-color: white;
  padding-left: 14px;
  padding-right: 14px;
  padding-top: 14px;
  padding-bottom: 8px;
`;

const ButtonDiv = styled.div`
  text-align: right;
  display: inline-block;
  margin-left: 15px;
`;
export { ReportsView };
