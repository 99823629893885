import { TagsOutlined } from '@ant-design/icons';
import { Button, Form, message, Modal, Select } from 'antd';
import axios from 'axios';
import React, { useContext, useState } from 'react';
import { authContext } from '../ProvideAuth.js';

function BankMassAutoCategorizer(props) {
  const auth = useContext(authContext);

  const autoCategorize = (txns) => {
    const data = {
      sotId: props.selectedSot.sources_of_truth_id,
      sotTxnsToUpdate: txns
        ? txns.map((x) => x.id)
        : '',
    };
    // console.log(data);
    // setAccountingAccountSubmitting(false);
    axios({
      method: 'post',
      url: props.API_domain + 'autoCategorizeSotTxns',
      data: data,
      auth: {
        username: auth.email,
        password: auth.token,
      },
    })
      .then((response) => {
        const count = response.data.count;
        props.setSubmittedToggle(!props.submittedToggle);
        message.success(`Se categorizaron ${count} transacciones`);
      })
      .catch((response) => {
        console.log(response);
        message.error('Error actualizando las cuentas contables');
      });
  };

  return (
    <div>
      {props.selectedSotTxnsInMassEdit &&
        props.selectedSotTxnsInMassEdit.length > 1 && (
          <Button
            onClick={() => {
              autoCategorize(props.selectedSotTxnsInMassEdit);
            }}
          >
            <TagsOutlined />
            Auto Categorizar ({props.selectedSotTxnsInMassEdit.length})
          </Button>
        )}
    </div>
  );
}

export { BankMassAutoCategorizer };
