import React, { useContext, useState, useEffect } from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import axios from 'axios';
import { authContext } from '../../ProvideAuth.js';
import { AccountingObligationsTable } from './AccountingObligationsTable.js';
import '../styles.css';

const transformAndSortData = (transactions) => {
  return transactions;
};

const AccountingObligationsTablesFetcher = (props) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const auth = useContext(authContext);
  const [clientNames, setClientNames] = useState({});

  const getAccountingObligations = () => {
    setLoading(true);
    axios({
      method: 'post',
      //   url: props.API_domain + 'getFlaggedSotTxns',
      url: props.API_domain + 'getAccountingObligations',
      auth: {
        username: auth.email,
        password: auth.token,
      },
    }).then((response) => {
      console.log('response.data', response.data);
      const transformedData = {};
      Object.keys(response.data.obligations).forEach((clientId) => {
        transformedData[clientId] = transformAndSortData(
          response.data.obligations[clientId]
        );
      });
      setData(transformedData);
      setClientNames(response.data.names);
      setLoading(false);
    });
  };

  useEffect(() => {
    getAccountingObligations();
  }, []);

  // Now do a for loop over the data object and render a table for each client
  const renderData = () => {
    return Object.keys(data).map((clientId) => {
      const clientTxns = data[clientId];
      return (
        <div key={clientId} style={{ marginTop: 26 }}>
          {Object.keys(data).length > 1 && (
            <h3>
              Obligaciones de <b>{clientNames[clientId].toUpperCase()}</b>
            </h3>
          )}
          <AccountingObligationsTable
            API_domain={props.API_domain}
            loading={loading}
            data={clientTxns}
            setRefresh={props.setRefresh}
            refresh={props.refresh}
            clientId={clientId}
            setShowCommentModal={props.setShowCommentModal}
            setCommentModalSotTxn={props.setCommentModalSotTxn}
          />
        </div>
      );
    });
  };

  if (loading) {
    return <LoadingDiv>{antIcon}</LoadingDiv>;
  }

  return <>{renderData()}</>;
};

const antIcon = <LoadingOutlined style={{ fontSize: 50 }} spin />;
const LoadingDiv = styled.div`
  margin-top: 150px;
  margin-bottom: 400px;
`;

export { AccountingObligationsTablesFetcher };
