import React, { useContext, useState } from 'react';
import { Layout, Row, Col } from 'antd';
import styled from 'styled-components/macro';
import { BankTable } from './BankTable.js';
import { SOTSelect } from './SOTSelect.js';
import { TableDataDownloader } from './TableDataDownloader.js';
import { SOTUploader } from './SOTUploader.js';
import { Refresh } from './Refresh.js';
import { MassEditButtonAndModalForSotTxns } from './MassEditButtonAndModalForSotTxns.js';
import { BankZohoDownloader } from './BankZohoDownloader.js';
import { BankMassAutoCategorizer } from './BankMassAutoCategorizer.js';
import { BankSimpleDownloader } from './BankSimpleDownloader.js';

const { Content } = Layout;

function BankView(props) {
  const [currentData, setCurrentData] = useState([]);
  const [selectedSot, setSelectedSot] = useState(0);
  const [uploadChanged, setUploadChanged] = useState(false);
  const [loading, setLoading] = useState(true);
  const [selectedSotTxnsInMassEdit, setSelectedSotTxnsInMassEdit] = useState(
    []
  );
  const [submittedToggle, setSubmittedToggle] = useState(false);
  const [accountingAccountOptions, setAccountingAccountOptions] = useState([]);
  // console.log(selectedSotTxnsInMassEdit);
  return (
    <Content
      style={{
        margin: '0 0 0',
        overflow: 'initial',
        borderLeft: 'solid rgb(235,235,235) 1px',
        borderRight: 'solid rgb(235,235,235) 1px',
        borderTop: 'solid rgb(235,235,235) 1px',
      }}
    >
      <AffixDiv>
        <Row>
          <Col span={9} style={{ textAlign: 'left' }}>
            <div
              style={{
                textAlign: 'left',
                paddingTop: 40,
                fontSize: 36,
                fontWeight: 'bold',
              }}
            >
              Banco
            </div>
            <Refresh
              onClick={() => setUploadChanged(!uploadChanged)}
              spin={loading}
            />
            <SOTSelect
              API_domain={props.API_domain}
              selectedSot={selectedSot}
              setSelectedSot={setSelectedSot}
            />
          </Col>
          <Col span={15} style={{ textAlign: 'right' }}>
            <ButtonDiv>
              <MassEditButtonAndModalForSotTxns
                API_domain={props.API_domain}
                selectedSotTxnsInMassEdit={selectedSotTxnsInMassEdit}
                setSelectedSotTxnsInMassEdit={setSelectedSotTxnsInMassEdit}
                submittedToggle={submittedToggle}
                setSubmittedToggle={setSubmittedToggle}
                accountingAccountOptions={accountingAccountOptions}
              />
            </ButtonDiv>
            <ButtonDiv>
              <BankMassAutoCategorizer
                API_domain={props.API_domain}
                selectedSot={selectedSot}
                selectedSotTxnsInMassEdit={selectedSotTxnsInMassEdit}
                setSelectedSotTxnsInMassEdit={setSelectedSotTxnsInMassEdit}
                submittedToggle={submittedToggle}
                setSubmittedToggle={setSubmittedToggle}
              />
            </ButtonDiv>
            <ButtonDiv
              style={{
                visibility:
                  Object.keys(selectedSot).length === 0 ? 'hidden' : 'visible',
              }}
            >
              <SOTUploader
                API_domain={props.API_domain}
                selectedSot={selectedSot}
                uploadChanged={uploadChanged}
                setUploadChanged={setUploadChanged}
              />
            </ButtonDiv>
            <ButtonDiv>
              <BankZohoDownloader
                data={currentData}
                selectedSot={selectedSot}
                accountingAccountOptions={accountingAccountOptions}
              />
            </ButtonDiv>
            <ButtonDiv>
              <TableDataDownloader data={currentData} />
            </ButtonDiv>
            <ButtonDiv>
              <BankSimpleDownloader 
                data={currentData}
                selectedSot={selectedSot}
                accountingAccountOptions={accountingAccountOptions}
               />
            </ButtonDiv>
          </Col>
        </Row>
        <BankTable
          API_domain={props.API_domain}
          selectedSot={selectedSot}
          setCurrentData={setCurrentData}
          uploadChanged={uploadChanged}
          loading={loading}
          setLoading={setLoading}
          setSelectedSotTxnsInMassEdit={setSelectedSotTxnsInMassEdit}
          accountingAccountOptions={accountingAccountOptions}
          setAccountingAccountOptions={setAccountingAccountOptions}
          submittedToggle={submittedToggle}
        />
      </AffixDiv>
    </Content>
  );
}

const AffixDiv = styled.div`
  background-color: white;
  padding-left: 14px;
  padding-right: 14px;
  padding-top: 14px;
  padding-bottom: 8px;
  max-width: 1400px;
  margin: auto;
`;

const ButtonDiv = styled.div`
  text-align: right;
  display: inline-block;
  margin-left: 10px;
`;

export { BankView };
