import { Select } from 'antd';
import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { authContext } from '../ProvideAuth.js';

function CardSelect(props) {
  const auth = useContext(authContext);
  const [options, setOptions] = useState();
  const [selectedCard, setSelectedCard] = useState();

  function fetchCardOptions() {
    axios({
      method: 'POST',
      url: props.API_domain + 'fetchCardOptions',
      auth: {
        username: auth.email,
        password: auth.token,
      },
    })
      .then(function (response) {
        let selectOptions = response.data.map((card) => ({
          label: card.label,
          value: card.id,
        }));
        setOptions(selectOptions);
      })
      .catch(function (response) {
        console.log(response);
      });
  }

  useEffect(() => {
    fetchCardOptions();
  }, []);

  useEffect(() => {
    // Update internal state when props.selectedCard changes
    setSelectedCard(props.selectedCard);
  }, [props.selectedCard]);

  const handleChange = (value) => {
    setSelectedCard(value);
    props.onCardSelect(value);
  };

  return (
    <>
      <span
        style={{
          backgroundColor: props.highlightedColor,
          padding: props.noPadding ? 0 : 12,
          borderRadius: 4,
        }}
      >
        <Select
          showSearch
          value={selectedCard}
          options={options}
          onChange={handleChange}
          placeholder='Selecciona Tarjeta'
          style={{ width: '300px' }}
          filterOption={(input, option) =>
            option.label.toLowerCase().includes(input.toLowerCase())
          }
        />
      </span>
    </>
  );
}

export { CardSelect };
