import React, { useEffect, useState } from 'react';
import { Button } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import { dateTransformer, downloadBlob } from '../utils.js';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

const BankSimpleDownloader = (props) => {
  const [transformedData, setTransformedData] = useState([]);

  // console.log(data);
  // 1. fecha 2. credit_account 3. debit_account 4. notas 5. monto
  useEffect(() => {
    const accountIdsToNameMap = props.accountingAccountOptions.reduce(
      (acc, curr) => {
        acc[curr.id] = curr.name;
        return acc;
      },
      {}
    );
    setTransformedData(
      props.data.map((row) => {
        const accountName = row.flagged ? 'Cuenta por revisar' : accountIdsToNameMap[row['accounting_account_id']];
        if (row['amount'] < 0) {
          return [
            dateTransformer(row['Fecha']),
            props.selectedSot.source_zoho_name,
            accountName,
            row['description'],
            Math.abs(row['amount']),
          ];
        } else {
          return [
            dateTransformer(row['Fecha']),
            accountName,
            props.selectedSot.source_zoho_name,
            row['description'],
            Math.abs(row['amount']),
          ];
        }
      })
    );
  }, [props.data, props.accountingAccountOptions]);

  const exportToCSV = () => {
    const header = [['Fecha', 'Cuenta de crédito', 'Cuenta de débito', 'Notas', 'Monto']];
    const csvData = header.concat(transformedData);
    const ws = XLSX.utils.aoa_to_sheet(csvData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    const csvBlob = new Blob([XLSX.write(wb, { bookType: 'csv', type: 'array' })], { type: 'text/csv;charset=utf-8;' });
    downloadBlob(csvBlob, 'zoho_manual_entries.csv');
  };

  return (
    <Button icon={<DownloadOutlined />} onClick={() => exportToCSV()}>
      Excel Simple
    </Button>
  );
};

export { BankSimpleDownloader };
