import React, { useContext, useState, useEffect } from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import axios from 'axios';
import { authContext } from '../../ProvideAuth.js';
import { AccountingInboxTable } from './AccountingInboxTable.js';
import '../styles.css';

const convertToIntDateFormat = (dateString) => {
  return new Intl.DateTimeFormat('es-US', {
    dateStyle: 'medium',
  }).format(
    new Date(
      dateString.split('-')[0],
      dateString.split('-')[1] - 1,
      dateString.split('-')[2]
    )
  );
};

const transformAndSortData = (transactions) => {
  return transactions
    .map((item) => ({
      ...item,
      key: item.id,
      Descripcion: item.description,
      Fecha: item.txn_date,
      Date: new Date(item.txn_date),
      fechaFormatted: convertToIntDateFormat(item.txn_date),
      Credito: item.amount > 0 ? Math.abs(item.amount) : null,
      Debito: item.amount < 0 ? Math.abs(item.amount) : null,
      balance: item.extra_info.balance,
      sotTxnId: item.id,
      flagged: item.flagged,
    }))
    .sort((a, b) => {
      const dateDifference = b.Date - a.Date;
      if (dateDifference !== 0) {
        return dateDifference;
      }
      return b.sotTxnId - a.sotTxnId;
    });
};

const AccountingInboxFetcher = (props) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const auth = useContext(authContext);
  const [clientNames, setClientNames] = useState({});

  const getFlaggedSotTxns = () => {
    setLoading(true);
    axios({
      method: 'get',
      url: props.API_domain + 'getFlaggedSotTxns',
      auth: {
        username: auth.email,
        password: auth.token,
      },
    }).then((response) => {
      const transformedData = {};
      Object.keys(response.data.txns).forEach((clientId) => {
        transformedData[clientId] = transformAndSortData(
          response.data.txns[clientId]
        );
      });
      setData(transformedData);
      setClientNames(response.data.names);
      setLoading(false);
    });
  };

  useEffect(() => {
    getFlaggedSotTxns();
    props.setRefresh(false);
  }, [props.refresh]);

  // Now do a for loop over the data object and render a table for each client
  const renderData = () => {
    return Object.keys(data).map((clientId) => {
      const clientTxns = data[clientId];
      return (
        <div key={clientId}>
          {Object.keys(data).length > 1 && (
            <h3>
              Transacciones de <b>{clientNames[clientId].toUpperCase()}</b>
            </h3>
          )}
          <AccountingInboxTable
            API_domain={props.API_domain}
            loading={loading}
            data={clientTxns}
            setRefresh={props.setRefresh}
            refresh={props.refresh}
            clientId={clientId}
            setShowCommentModal={props.setShowCommentModal}
            setCommentModalSotTxn={props.setCommentModalSotTxn}
          />
        </div>
      );
    });
  };

  if (loading) {
    return <LoadingDiv>{antIcon}</LoadingDiv>;
  }

  return <>{renderData()}</>;
};

const antIcon = <LoadingOutlined style={{ fontSize: 50 }} spin />;
const LoadingDiv = styled.div`
  margin-top: 150px;
  margin-bottom: 400px;
`;

export { AccountingInboxFetcher };
