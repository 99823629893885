import React, { useContext, useEffect, useState } from 'react';
import { Button, message, Table, Tooltip } from 'antd';
import { EyeOutlined, LeftOutlined, RightOutlined } from '@ant-design/icons';
import { FormattedUSD } from '../FormattedUSD';
import axios from 'axios';
import { authContext } from '../../ProvideAuth.js';
import './../styles.css';

const AccountingObligationsTable = (props) => {
  const [loading, setLoading] = useState(false);
  const auth = useContext(authContext);
  const [columns, setColumns] = useState([]);
  const [fullData, setFullData] = useState(props.data);
  const [monthOffset, setMonthOffset] = useState(0);

  const getMonthName = (offset) => {
    const date = new Date();
    date.setMonth(date.getMonth() + offset);
    return date.toLocaleString('es-ES', { month: 'long' });
  };

  const trackAndOpenLink = (record) => {
    const trackingData = {
      event: 'AccountingObligationsVerMasClicked',
      properties: {
        // Add any relevant properties here
        actividad: record.actividad,
      },
    };

    axios({
      method: 'post',
      url: props.API_domain + 'trackFrontEndEvent', // Adjust the URL accordingly
      auth: {
        username: auth.email,
        password: auth.token,
      },
      data: trackingData,
    })
      .then((response) => {
        console.log('Event tracked:', response);
        // Open the link after successful tracking
        window.open(record.link, '_blank');
      })
      .catch((error) => {
        console.error('Error tracking event:', error);
        // Consider whether to still open the link in case of an error
      });
  };

  const generateColumns = () => {
    const cols = [
      {
        title: 'Actividad',
        dataIndex: 'actividad',
        key: 'actividad',
        width: 200,
        render: (text, record) => {
          return (
            <>
              <div style={{ fontWeight: 'bold' }}>{text}</div>
              {record.link && (
                <Button
                  type='secondary'
                  style={{ marginTop: 5, fontSize: 10, marginTop: 12 }}
                  onClick={() => trackAndOpenLink(record)}
                  size='small'
                >
                  <EyeOutlined />
                  Ver más
                </Button>
              )}
            </>
          );
        },
      },
    ];

    for (let i = 0; i < 4; i++) {
      const date = new Date();
      date.setMonth(new Date().getMonth() + monthOffset + i);
      let title = date.toLocaleString('es-ES', {
        month: 'long',
        year: 'numeric',
      });

      cols.push({
        title: (
          <span
            style={{
              fontWeight:
                new Date().getMonth() === date.getMonth() && monthOffset === 0
                  ? 'bold'
                  : 'normal',
            }}
          >
            {title}
          </span>
        ),
        dataIndex: `month${i}`,
        key: `month${i}`,
        width: 150,
        render: (text, record) => (
          <div
            style={{
              color:
                new Date() > new Date(text?.formattedDueDate) &&
                text?.paid !== true
                  ? 'red'
                  : 'black',
              height: '60px',
              width: '180px',
              padding: '5px',
            }}
          >
            {text?.formattedDueDate && <div>{text.formattedDueDate}</div>}
            {text?.amount && (
              <div style={{ fontWeight: 'bold' }}>
                <Tooltip
                  title={
                    'AccountingObligationsId: ' + text?.accountingObligationsId
                  }
                  placement='left'
                >
                  {text.amount > 0 ? (
                    <>
                      <FormattedUSD total={text.amount} />{' '}
                    </>
                  ) : (
                    ''
                  )}
                  {/* <FormattedUSD total={text.amount} />{' '} */}
                  {text.paid ? (
                    <Tooltip title='Pagado'>✅</Tooltip>
                  ) : (
                    // if text.paid is not true and formattedDueDate is in the past, show a warning. Otherwise, if formattedDueDate is this month, show a clock
                    <>
                      {new Date() > new Date(text?.formattedDueDate) ? (
                        <Tooltip title='Atrasado'>❗❗</Tooltip>
                      ) : // if formattedDueDate is this month, show a clock, otherwise, don't show anything
                      new Date().getMonth() === date.getMonth() &&
                        monthOffset === 0 ? (
                        <Tooltip title='Pendiente. Págalo pronto.'>⏳</Tooltip>
                      ) : (
                        ''
                      )}
                    </>
                  )}
                </Tooltip>
              </div>
            )}
          </div>
        ),
      });
    }

    return cols;
  };

  const convertToIntDateFormat = (dateString) => {
    const [datePart] = dateString.split(' '); // Splitting and taking the date part
    const [year, month, day] = datePart
      .split('-')
      .map((part) => parseInt(part, 10)); // Converting parts to integers

    return new Intl.DateTimeFormat('es-US', {
      dateStyle: 'medium',
    }).format(new Date(year, month - 1, day)); // Month is 0-indexed
  };

  const calculateMonthIndex = (itemDate) => {
    const itemDateTime = new Date(itemDate);
    const currentDateTime = new Date();
    currentDateTime.setMonth(currentDateTime.getMonth() + monthOffset);

    const yearDiff = itemDateTime.getFullYear() - currentDateTime.getFullYear();
    const monthDiff = itemDateTime.getMonth() - currentDateTime.getMonth();

    return yearDiff * 12 + monthDiff;
  };

  const organizeData = (responseData, offset) => {
    // Extracting unique 'obligation_enum_name' values
    const activities = [
      ...new Set(responseData.map((item) => item.obligation_enum_name)),
    ];

    // Filtering activities to include only those with relevant due dates and unpaid past obligations
    const filteredActivities = activities.filter((obligationEnumName) => {
      // Check if there is any valid item for this activity
      return responseData.some((item) => {
        const itemDate = new Date(item.due_date);
        const currentDate = new Date();
        currentDate.setMonth(currentDate.getMonth() + offset);

        return (
          item.obligation_enum_name === obligationEnumName &&
          (!item.paid || itemDate >= currentDate)
        );
      });
    });

    return filteredActivities
      .map((obligationEnumName) => {
        // Find the first item with a valid link for this activity
        const firstValidLinkItem = responseData.find(
          (item) =>
            item.obligation_enum_name === obligationEnumName &&
            item.obligation_how_to_link
        );

        const rowData = {
          actividad: obligationEnumName,
          link: firstValidLinkItem
            ? firstValidLinkItem.obligation_how_to_link
            : null,
        };

        // Initialize all months with empty data
        for (let i = 0; i < 4; i++) {
          rowData[`month${i}`] = null;
        }

        // Fill in the data for months where this activity has data
        responseData.forEach((item) => {
          const itemDate = new Date(item.due_date);
          const currentDate = new Date();

          if (
            item.obligation_enum_name === obligationEnumName &&
            !(itemDate < currentDate && item.paid)
          ) {
            const monthIndex = calculateMonthIndex(item.due_date);
            if (monthIndex >= 0 && monthIndex < 4) {
              rowData[`month${monthIndex}`] = {
                accountingObligationsId: item.id,
                dueDate: item.due_date,
                amount: item.amount,
                paid: item.paid,
                formattedDueDate: convertToIntDateFormat(item.due_date),
              };
            }
          }
        });

        return rowData;
      })
      .filter((rowData) => {
        // Ensure the row data has at least one non-null month entry
        return Object.keys(rowData).some(
          (key) => key.startsWith('month') && rowData[key] !== null
        );
      });
  };

  const [data, setData] = useState(organizeData(props.data, monthOffset));
  useEffect(() => {
    setColumns(generateColumns()); // Update columns when monthOffset changes
  }, [monthOffset]); // Add monthOffset as a dependency

  useEffect(() => {
    if (fullData) {
      setData(organizeData(fullData, monthOffset)); // Reorganize data when monthOffset changes
    }
  }, [monthOffset, fullData]);

  const toggleNextMonth = () => {
    setMonthOffset((prevOffset) => (prevOffset + 1) % 12);
  };

  const togglePreviousMonth = () => {
    setMonthOffset((prevOffset) => (prevOffset > 0 ? prevOffset - 1 : 0));
  };

  return (
    <>
      <div
        style={{
          padding: '25px',
          backgroundColor: '#f0f2f5',
          borderRadius: '6px',
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: 16,
          }}
        >
          <h2 style={{ margin: 0 }}>Obligaciones</h2>
          <div>
            <Button
              onClick={togglePreviousMonth}
              style={{ marginRight: 8 }}
              disabled={monthOffset === 0}
            >
              <Tooltip title={getMonthName(monthOffset - 1)}>
                <LeftOutlined />
              </Tooltip>
            </Button>
            <Button onClick={toggleNextMonth}>
              <Tooltip title={getMonthName(monthOffset + 3)}>
                <RightOutlined />
              </Tooltip>
            </Button>
          </div>
        </div>
        <Table
          rowClassName={() => 'no-hover-effect'}
          columns={columns}
          dataSource={data}
          pagination={false}
          bordered
          loading={loading}
          size='large'
          summary={(pageData) => {
            let totalDue = { month0: 0, month1: 0, month2: 0, month3: 0 };
            pageData.forEach((item) => {
              totalDue.month0 += parseFloat(item.month0?.amount || 0);
              totalDue.month1 += parseFloat(item.month1?.amount || 0);
              totalDue.month2 += parseFloat(item.month2?.amount || 0);
              totalDue.month3 += parseFloat(item.month3?.amount || 0);
            });

            return (
              <Table.Summary.Row
                // style={{ background: '#fafafa', fontWeight: 'bold' }}
                style={{ background: '#F7F9FC', fontWeight: 'bold' }}
              >
                <Table.Summary.Cell>Total de obligaciones</Table.Summary.Cell>
                <Table.Summary.Cell>
                  <FormattedUSD total={totalDue.month0.toFixed(2)} />
                </Table.Summary.Cell>
                <Table.Summary.Cell>
                  <FormattedUSD total={totalDue.month1.toFixed(2)} />
                </Table.Summary.Cell>
                <Table.Summary.Cell>
                  <FormattedUSD total={totalDue.month2.toFixed(2)} />
                </Table.Summary.Cell>
                <Table.Summary.Cell>
                  <FormattedUSD total={totalDue.month3.toFixed(2)} />
                </Table.Summary.Cell>
              </Table.Summary.Row>
            );
          }}
        />
      </div>
    </>
  );
};

export { AccountingObligationsTable };
