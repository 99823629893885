import { Button, Table, Affix } from 'antd';
import {
  DownloadOutlined,
  FullscreenOutlined,
  FullscreenExitOutlined,
} from '@ant-design/icons';
import React, { useContext, useState } from 'react';
import { FormattedUSD } from '../FormattedUSD.js';
import { AccountingFinancialStatementsModal } from './AccountingFinancialStatementsModal.js';
import * as XLSX from 'xlsx';
import { authContext } from '../../ProvideAuth.js';
import axios from 'axios';

const IncomeStatementTable2 = (props) => {
  const auth = useContext(authContext);
  const [data] = useState(props.incomeStatementData);
  const [timePeriods] = useState(props.incomeStatementRecentTimePeriods);
  const [expandedKeys, setExpandedKeys] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalContent, setModalContent] = useState({});

  const handleExpand = (expanded, record) => {
    setExpandedKeys((prevKeys) => {
      if (expanded) {
        return [...prevKeys, record.key];
      } else {
        return prevKeys.filter((key) => key !== record.key);
      }
    });
  };

  const handleCollapseAll = () => {
    setExpandedKeys([]);
  };

  const handleExpandAll = () => {
    const getAllKeys = (nodes) => {
      let keys = [];
      nodes.forEach((node) => {
        keys.push(node.key);
        if (node.children) {
          keys = keys.concat(getAllKeys(node.children));
        }
      });
      return keys;
    };
    setExpandedKeys(getAllKeys(data));
  };

  const showModal = (record, month) => {
    setModalContent({ ...record, month });
    setIsModalVisible(true);
  };

  const createColumns = (data) => {
    const valueColumns =
      data.length > 0 && Array.isArray(data[0].values)
        ? data[0].values.map((_, index) => ({
            title: (
              <div style={{ textAlign: 'right', fontWeight: 'bold' }}>
                {timePeriods[index] || `Value ${index + 1}`}
              </div>
            ),
            dataIndex: `value${index + 1}`,
            key: `value${index + 1}`,
            render: (values, record) =>
              values &&
              Array.isArray(values) &&
              values[index] !== undefined &&
              !record.children?.length &&
              record.subcategory !== 'UTILIDAD NETA' ? (
                <Button
                  type='link'
                  className='hyperlink'
                  onClick={() =>
                    showModal(
                      record,
                      timePeriods[index] || `Value ${index + 1}`
                    )
                  }
                  style={{ width: '100%', textAlign: 'right', paddingRight: 0 }} // Ensure full width and right alignment
                >
                  <div style={{ display: 'block', textAlign: 'right' }}>
                    <FormattedUSD total={values[index]} />
                  </div>
                </Button>
              ) : values &&
                Array.isArray(values) &&
                values[index] !== undefined ? (
                <span
                  style={{
                    fontWeight: isParentBold(record) ? 'bold' : 'normal',
                    textAlign: 'right', // Add this line for right alignment
                    display: 'block', // Ensure it behaves like a block-level element for alignment
                  }}
                >
                  <FormattedUSD total={values[index]} />
                </span>
              ) : (
                ''
              ),
          }))
        : [];

    const isParentBold = (record) => {
      if (record.children && record.children.length > 0) {
        return expandedKeys.includes(record.key);
      }
      return false;
    };

    return [
      {
        title: 'Cuenta',
        dataIndex: 'subcategory',
        key: 'subcategory',
        width: 400, // Increase the width of the subcategory column
        render: (text, record) => (
          <span
            style={{
              fontWeight: isParentBold(record) ? 'bold' : 'normal',
            }}
          >
            {text}
          </span>
        ),
      },
      ...valueColumns,
    ];
  };

  const createDataSource = (nodes) => {
    const traverseNodes = (nodes, level = 1) => {
      return nodes.map((node) => {
        const newNode = {
          key: node.key,
          category: node.category,
          subcategory: node.subcategory,
          values: node.values,
          account_id: node.account_id,
        };
        node.values.forEach((value, index) => {
          newNode[`value${index + 1}`] = node.values;
        });

        if (node.children && node.children.length > 0) {
          newNode.children = traverseNodes(node.children, level + 1);
        }

        return newNode;
      });
    };

    return traverseNodes(nodes);
  };

  const columns = createColumns(data);
  const dataSource = createDataSource(data);

  const exportToExcel = () => {
    const traverseNodes = (nodes, level = 0, result = []) => {
      nodes.forEach((node) => {
        result.push({
          Subcategory: ' '.repeat(level * 4) + node.subcategory,
          ...node.values.reduce((acc, val, idx) => {
            acc[timePeriods[idx] || `Value ${idx + 1}`] = val;
            return acc;
          }, {}),
        });

        if (node.children && node.children.length > 0) {
          traverseNodes(node.children, level + 1, result);
        }
      });

      return result;
    };

    const flattenedData = [];

    // Separate nodes by category and add empty line between categories
    ['Income', 'Expenses', 'Net Income'].forEach((category) => {
      const categoryNodes = data.filter((node) => node.category === category);
      if (categoryNodes.length > 0) {
        flattenedData.push(...traverseNodes(categoryNodes));
        flattenedData.push({}); // Add empty line
      }
    });

    const worksheet = XLSX.utils.json_to_sheet(flattenedData);

    // Remove the header "Subcategory"
    delete worksheet['A1'];

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Balance Sheet');

    XLSX.writeFile(workbook, 'income_statement.xlsx');

    const trackingData = {
      event: 'exportIncomeStatementToExcel',
      properties: {},
    };
    axios({
      method: 'post',
      url: props.API_domain + 'trackFrontEndEvent',
      auth: {
        username: auth.email,
        password: auth.token,
      },
      data: trackingData,
    })
      .then((response) => {
        console.log('Event tracked:', response);
      })
      .catch((error) => {
        console.error('Error tracking event:', error);
      });
  };

  return (
    <div style={{ textAlign: 'left' }}>
      <Affix offsetTop={0}>
        <div
          style={{
            marginBottom: '10px',
            backgroundColor: 'white', // Ensures the background is consistent
            padding: '10px', // Optional: for some padding around the buttons
            display: 'flex', // Display the divs side by side
            justifyContent: 'space-between', // Add space between the divs
          }}
        >
          <div>
            <Button style={{ margin: 4 }} onClick={handleCollapseAll}>
              <FullscreenExitOutlined />
              Colapsar
            </Button>
            <Button style={{ margin: 4 }} onClick={handleExpandAll}>
              <FullscreenOutlined />
              Expandir
            </Button>
          </div>
          <div>
            <Button
              style={{ margin: 4, marginLeft: 12, textAlign: 'right' }}
              onClick={exportToExcel}
            >
              <DownloadOutlined /> Excel
            </Button>
          </div>
        </div>
      </Affix>
      <Table
        columns={columns}
        dataSource={dataSource}
        pagination={false}
        expandable={{
          expandedRowKeys: expandedKeys,
          onExpand: handleExpand,
        }}
        scroll={{ x: true }} // Enable horizontal scrolling
        style={{
          width: '1000px',
        }}
        rowKey='key'
      />
      <AccountingFinancialStatementsModal
        API_domain={props.API_domain}
        accountingClientCompany={props.accountingClientCompany}
        modalContent={modalContent}
        isModalVisible={isModalVisible}
        title='Additional Information'
        visible={isModalVisible}
        onOk={() => setIsModalVisible(false)}
        onCancel={() => {
          setIsModalVisible(false);
          setModalContent({});
        }}
      />
    </div>
  );
};

export { IncomeStatementTable2 };
