import { LoadingOutlined, UploadOutlined } from '@ant-design/icons';
import { Button, Form, Input, Modal, Spin, Upload, message, Radio } from 'antd';
import axios from 'axios';
import { useContext, useState } from 'react';
import styled from 'styled-components/macro';
import { read, utils } from 'xlsx';
import {
  downloadBlob,
  get_zoho_manual_entries_from_csv_blob_FE_ventas,
  get_zoho_manual_entries_from_csv_blob_FE_compras,
} from '../../utils.js';

const layout = {
  labelCol: {
    span: 12,
  },
  wrapperCol: {
    span: 12,
  },
};

const excelDateToJSDate = (serial) => {
  const epoch = new Date(1899, 11, 31);
  const excelDate = new Date(epoch.getTime() + (serial - 1) * 86400000);
  return excelDate;
};

const formatDate = (date) => {
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0'); //January is 0!
  const year = date.getFullYear().toString().substr(-2);

  return month + '/' + day + '/' + year;
};

function CsvUploader(props) {
  const [files, setFiles] = useState([]);

  function isExcelDate(num) {
    return num > 44000 && num < 46000;
  }

  function isDateStringFormat(str) {
    return /^(\d{1,2})\/(\d{1,2})\/(\d{4})$/.test(str);
  }

  // Function to determine which column most likely contains dates
  function getDateColumn(jsonData) {
    let potentialDateColumns = {};
    for (let row of jsonData) {
      for (let [index, cell] of row.entries()) {
        if (isExcelDate(cell) || isDateStringFormat(cell)) {
          potentialDateColumns[index] = (potentialDateColumns[index] || 0) + 1;
        }
      }
    }

    let maxCount = 0;
    let dateColumn = -1;
    for (let [index, count] of Object.entries(potentialDateColumns)) {
      if (count > maxCount) {
        maxCount = count;
        dateColumn = parseInt(index, 10);
      }
    }
    return dateColumn;
  }

  function getMostCommonMonth(columnData) {
    let monthCounts = {};

    for (let cell of columnData) {
      if (typeof cell === 'number' && isExcelDate(cell)) {
        const date = excelDateToJSDate(cell);
        const month = date.getMonth() + 1; // Months are 0-indexed
        monthCounts[month] = (monthCounts[month] || 0) + 1;
      } else if (typeof cell === 'string' && isDateStringFormat(cell)) {
        const [first, second] = cell.split('/').map(Number);
        if (first <= 12) {
          monthCounts[first] = (monthCounts[first] || 0) + 1;
        }
        if (second <= 12) {
          monthCounts[second] = (monthCounts[second] || 0) + 1;
        }
      }
    }

    let commonMonth = null;
    let maxCount = 0;
    for (let [month, count] of Object.entries(monthCounts)) {
      if (count > maxCount) {
        maxCount = count;
        commonMonth = parseInt(month, 10);
      }
    }
    return commonMonth;
  }

  const uploadChanged = (event) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      if (!e.target || !e.target.result) return;
      const data = new Uint8Array(e.target.result);
      const workbook = read(data, { type: 'array' });

      // Process the workbook data as needed
      const worksheet = workbook.Sheets[workbook.SheetNames[0]];
      var jsonData = utils.sheet_to_json(worksheet, { header: 1 });

      const dateColumn = getDateColumn(jsonData);
      const dateColumnData = jsonData.map((row) => row[dateColumn]);
      const commonMonth = getMostCommonMonth(dateColumnData);

      jsonData = jsonData.map((row) => {
        return row.map((cell, index) => {
          if (index === dateColumn) {
            if (typeof cell === 'number' && isExcelDate(cell)) {
              const date = excelDateToJSDate(cell);
              return formatDate(date);
            } else if (typeof cell === 'string' && isDateStringFormat(cell)) {
              let [first, second, year] = cell.split('/').map(Number);
              if (first <= 12 && second <= 12) {
                // Ambiguous date, choose the part that matches the most common month
                if (first === commonMonth) {
                  // Interpret as mm/dd/yyyy
                } else if (second === commonMonth) {
                  // Interpret as dd/mm/yyyy
                  [first, second] = [second, first];
                }
              }
              const date = new Date(year, first - 1, second);
              return formatDate(date);
            }
          }
          return cell;
        });
      });

      props.setCsvData(jsonData);
    };
    reader.readAsArrayBuffer(event.file.originFileObj);
  };

  const fileRemoved = (event) => {
    const filteredFiles = files.filter((file) => file !== event);
    setFiles(filteredFiles);
  };

  return (
    <Upload
      name='file'
      showUploadList={{ showRemoveIcon: true }}
      accept='.xls, .xlsx, .csv'
      onChange={(e) => uploadChanged(e)}
      onRemove={(e) => fileRemoved(e)}
      // capture={undefined}
    >
      <Button icon={<UploadOutlined />}>Click to Upload</Button>
    </Upload>
  );
}

function AdminZohoConverterFE(props) {
  const [isSubmitting, setSubmitting] = useState(false);
  const [form] = Form.useForm();
  const [visible, setVisible] = useState(false);
  const [csvData, setCsvData] = useState([]);
  const [itbmsAccount, setItbmsAccount] = useState(''); // State for itbmsAccount
  const [downloader, setDownloader] = useState(); // State for selected radio button value

  const onChange = (e) => {
    console.log('radio checked', e.target.value);
    setDownloader(e.target.value);
  };

  const showModal = () => {
    setVisible(true);
  };

  const handleOk = () => {
    setVisible(false);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const onFinish = (values) => {
    let zoho_manual_entries_blob;

    // Determine which utility function to call based on selected radio button
    if (downloader === 'FE Ventas') {
      zoho_manual_entries_blob =
        get_zoho_manual_entries_from_csv_blob_FE_ventas(csvData, itbmsAccount);
    } else if (downloader === 'FE Compras') {
      zoho_manual_entries_blob =
        get_zoho_manual_entries_from_csv_blob_FE_compras(csvData, itbmsAccount);
    } else {
      message.error('Please select either FE Ventas or FE Compras');
      return;
    }

    downloadBlob(zoho_manual_entries_blob, 'zoho_manual_entries.csv');
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <>
      <Button type='secondary' onClick={showModal}>
        <ButtonSpan>Convertidor FE a ZOHO</ButtonSpan>
      </Button>
      <Modal
        title='Convertidor a ZOHO'
        visible={visible}
        width={600}
        footer={
          <Button
            type='primary'
            htmlType='submit'
            block
            style={{ fontWeight: 500, marginTop: 10, marginBottom: 10 }}
            onClick={onFinish}
          >
            {isSubmitting ? <Spin indicator={antIcon} /> : <>Convertir</>}
          </Button>
        }
        onCancel={handleCancel}
        centered
      >
        <Form
          {...layout}
          name='basic'
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          form={form}
        >
          {/* Input field for ITBMS Account */}
          <Form.Item
            name='itbmsAccount'
            label='Nombre de cuenta contable de ITBMS'
            rules={[
              {
                required: true,
                message: 'Por favor incluye la cuenta. o pon un espacio',
              },
            ]}
          >
            <Input
              value={itbmsAccount}
              onChange={(e) => setItbmsAccount(e.target.value)}
            />
          </Form.Item>
          <Radio.Group
            onChange={onChange}
            value={downloader}
            style={{ marginBottom: 16 }}
          >
            <Radio value='FE Ventas'>FE Ventas</Radio>
            <Radio value='FE Compras'>FE Compras</Radio>
          </Radio.Group>
          {/* Display the expected column order */}
          <div style={{ marginBottom: '16px' }}>
            <span style={{ fontWeight: 'bold' }}>COLUMNAS:</span>
            <ol>
              <li>fecha</li>
              <li>credit_account</li>
              <li>debit_account</li>
              <li>notas</li>
              <li>monto</li>
              <li>itbms (optional)</li>
            </ol>
          </div>

          <CsvUploader setCsvData={setCsvData} />
        </Form>
      </Modal>
    </>
  );
}

const ButtonSpan = styled.span`
  font-weight: 500;
`;

const antIcon = <LoadingOutlined spin style={{ color: 'white' }} />;

export { AdminZohoConverterFE };
