import { LoadingOutlined } from '@ant-design/icons';
import {
  Button,
  Col,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Spin,
  message,
  Tooltip,
  Switch,
} from 'antd';
import axios from 'axios';
import React, { useContext, useState } from 'react';
import { authContext } from '../../ProvideAuth.js';
const { Option } = Select;

const layout = {
  labelCol: {
    span: 9,
  },
  wrapperCol: {
    span: 15,
  },
};

function ConfigEditAccountingUser(props) {
  const [isSubmitting, setSubmitting] = useState(false);
  const auth = useContext(authContext);
  const [form] = Form.useForm();
  const [isAdmin, setIsAdmin] = useState(
    props.member.stripe_subscription_id ? true : false
  );
  const [gptEnabledBot, setGptEnabledBot] = useState(
    props.member.gpt_enabled_bot
  );
  console.log(props);
  function decodePhoneNumber(input) {
    let countryCode;
    let phoneNumber;

    if (input.startsWith('whatsapp:+507')) {
      countryCode = '507';
    } else if (input.startsWith('whatsapp:+57')) {
      countryCode = '57';
    } else if (input.startsWith('whatsapp:+1')) {
      countryCode = '1';
    } else if (input.startsWith('whatsapp:+34')) {
      countryCode = '34';
    } else {
      return 'Invalid input';
    }
    phoneNumber = input.replace(`whatsapp:+${countryCode}`, '');
    return { countryCode, phoneNumber };
  }

  const { countryCode, phoneNumber } = decodePhoneNumber(props.member.phone);

  let initialValues = {
    id: props.member.id,
    name: props.member.name,
    countryCode, // Set countryCode
    phone: phoneNumber, // Set phone without the countryCode
    email: props.member.email,
    expense_creator: props.member.expense_creator,
    approver: props.member.approver,
    email_on_creation: props.member.email_on_creation,
    email_on_approval: props.member.email_on_approval,
    email_on_reimbursement: props.member.email_on_reimbursement,
    isAdmin: isAdmin,
  };

  const onFinish = (values) => {
    setSubmitting(true);
    const data = {
      id: props.member.id,
      team: props.team,
      name: values.name,
      phone: `whatsapp:+${values.countryCode}${values.phone}`, // Concatenate countryCode and phone number
      email: values.email,
      expense_creator: values.expense_creator,
      approver: values.approver,
      email_on_creation: values.email_on_creation,
      email_on_approval: values.email_on_approval,
      email_on_reimbursement: values.email_on_reimbursement,
      subscriptionId: isAdmin,
      gptEnabledBot: gptEnabledBot,
    };
    axios({
      method: 'post',
      url: props.API_domain + 'updateUserNamePhoneEmail', //updateUserNamePhoneEmail
      data: data,
      auth: {
        username: auth.email,
        password: auth.token,
      },
    })
      .then((response) => {
        props.onSubmit();
        props.handleOk();
        setTimeout(() => setSubmitting(false), 2000);
      })
      .catch(function (error) {
        message.error(error.response.data);
        setSubmitting(false);
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const showDeleteUserConfirm = (record) => {
    Modal.confirm({
      title:
        'Estás seguro de que quieres eliminar este usuario?\n\n No podrás deshacer esta acción.',
      okText: 'Sí',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        removeSubscriptionUser(record);
      },
    });
  };

  const removeSubscriptionUser = (user) => {
    axios({
      method: 'post',
      url: props.API_domain + 'removeSubscriptionUser',
      data: { userId: user.id, subscriptionId: props.subscriptionId },
      auth: {
        username: auth.email,
        password: auth.token,
      },
    })
      .then((response) => {
        if (response.status === 200) {
          props.onSubmit();
          props.handleOk();
          setSubmitting(!isSubmitting);
          message.success('Usuario borrado exitosamente');
        } else {
          console.log('Failed to remove user');
        }
      })
      .catch((error) => {
        console.log('Error removing user:', error);
      });
  };

  return (
    <Form
      {...layout}
      name='basic'
      initialValues={initialValues}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      form={form}
    >
      <Form.Item
        name='name'
        label='Nombre:'
        rules={[
          {
            required: true,
            message: 'Por favor escriba el nombre del colaborador',
          },
        ]}
      >
        <Input placeholder='Juan Pérez' />
      </Form.Item>

      <Form.Item label='WhatsApp (sin guiones)' style={{ marginBottom: 0 }}>
        <Row>
          <Col span={8}>
            <Form.Item
              name='countryCode'
              initialValue={'507'}
              rules={[
                {
                  required: true,
                  message: 'Please select a country code!',
                },
              ]}
              style={{ marginRight: 3 }}
            >
              <Select
                // showSearch
                defaultValue='507'
              >
                <Option value='1'>
                  <span role='img' aria-label='United States'>
                    🇺🇸
                  </span>{' '}
                  +1
                </Option>
                <Option value='57'>
                  <span role='img' aria-label='Colombia'>
                    🇨🇴
                  </span>{' '}
                  +57
                </Option>
                <Option value='507'>
                  <span role='img' aria-label='Panama'>
                    🇵🇦
                  </span>{' '}
                  +507
                </Option>
                <Option value='34'>
                  <span role='img' aria-label='Espana'>
                    🇪🇸
                  </span>{' '}
                  +34
                </Option>
                <Option value='58'>
                  <span role='img' aria-label='Venezuela'>
                    🇻🇪
                  </span>{' '}
                  +58
                </Option>
                <Option value='55'>
                  <span role='img' aria-label='Brazil'>
                    🇧🇷
                  </span>{' '}
                  +55
                </Option>
                <Option value='39'>
                  <span role='img' aria-label='Italy'>
                    🇮🇹
                  </span>{' '}
                  +39
                </Option>
                <Option value='44'>
                  <span role='img' aria-label='UK'>
                    🇬🇧
                  </span>{' '}
                  +44
                </Option>
                <Option value='971'>
                  <span role='img' aria-label='UAE'>
                    🇦🇪
                  </span>{' '}
                  +971
                </Option>
                {/* Add more options for other countries here */}
              </Select>
            </Form.Item>
          </Col>
          <Col span={16}>
            <Form.Item
              name='phone'
              rules={[
                {
                  required: true,
                  message: 'Por favor ingresa tu número de WhatsApp',
                },
              ]}
            >
              <Input type='number' placeholder='66123476' />
            </Form.Item>
          </Col>
        </Row>
      </Form.Item>
      <Form.Item name='email' label='Email'>
        <Input placeholder='juanperez@gmail.com' />
      </Form.Item>
      <Form.Item name='admin' label='Administrador' initialValue={false}>
        <Tooltip
          title={
            <>
              Podrá<div> - Habilitar/Deshabilitar a otros Admins</div>
              <div>- Ver y seditar todos los usuarios de la compañía</div>
            </>
          }
          placement='right'
        >
          <Switch
            defaultChecked={isAdmin}
            onChange={(checked) => {
              setIsAdmin(checked);
            }}
          />
        </Tooltip>
      </Form.Item>
      <Form.Item
        name='admin'
        label='Auto categorizador 🪄🦄'
        initialValue={false}
      >
        <Tooltip
          title={
            <>
              <div>
                Al ingresar gastos por WhatsApp, el bot intentará adivinar el
                equipo y categoría del gasto.
              </div>
              <br></br>
              <div>
                Esto hará más rápido tu ingreso de gastos ⚡ y te ayudará a
                mantener una clasificación consistente.
              </div>
            </>
          }
          placement='right'
        >
          <Switch
            defaultChecked={gptEnabledBot}
            onChange={(checked) => {
              setGptEnabledBot(checked);
            }}
          />
        </Tooltip>
      </Form.Item>
      <Row>
        <Col span={16}>
          <Button
            type='primary'
            htmlType='submit'
            block
            style={{ fontWeight: 500 }}
          >
            {isSubmitting ? <Spin indicator={antIcon} /> : 'Actualizar Usuario'}
          </Button>
        </Col>
        <Col span={8}>
          <Button
            block
            danger
            style={{ fontWeight: 500, margin: '0 4px' }}
            onClick={() => showDeleteUserConfirm(props.member)}
          >
            Eliminar usuario
          </Button>
        </Col>
      </Row>
    </Form>
  );
}
const antIcon = <LoadingOutlined spin style={{ color: 'white' }} />;

export { ConfigEditAccountingUser };
