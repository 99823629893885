import React, { useContext, useState } from 'react';
import { Button, Col, Layout, Row, message, Modal, Input, Divider } from 'antd';
import styled from 'styled-components/macro';
import { AccountingInboxFetcher } from './AccountingInboxFetcher.js';
import { AccountingObligationsTablesFetcher } from './AccountingObligationsTablesFetcher.js';
import { authContext } from '../../ProvideAuth.js';
import axios from 'axios';
import { FormattedUSD } from '../FormattedUSD.js';

const { Content } = Layout;

function AccountingInboxView(props) {
  // const [loading, setLoading] = useState(true);
  const auth = useContext(authContext);
  const [refresh, setRefresh] = useState(false);
  const [showCommentModal, setShowCommentModal] = useState(false);
  const [commentModalSotTxn, setCommentModalSotTxn] = useState(null);
  const [commentText, setCommentText] = useState('');
  const [commentModalLoading, setCommentModalLoading] = useState(false);

  const handleSubmitComment = () => {
    setCommentModalLoading(true);
    axios({
      method: 'post',
      url: props.API_domain + 'sendCommentNotification',
      auth: {
        username: auth.email,
        password: auth.token,
      },
      data: {
        sotTxn: commentModalSotTxn,
        comment: commentText,
      },
    })
      .then((response) => {
        // console.log('Comment submitted:', response);
        setCommentModalLoading(false);
        setShowCommentModal(false);
        setCommentText('');
        message.success('Comentario enviado');
      })
      .catch((error) => {
        setCommentModalLoading(false);
        message.error('Error al enviar comentario');
        console.error('Error submitting comment:', error);
      });
  };

  return (
    <>
      <Modal
        visible={showCommentModal}
        title='Comentario de transacción'
        width={800}
        onCancel={() => {
          setShowCommentModal(false);
          setCommentText('');
        }}
        onOk={() => {
          setShowCommentModal(false);
          setCommentText('');
        }}
        footer={
          <>
            <Button key='submit' type='primary' onClick={handleSubmitComment}>
              {commentModalLoading ? 'Enviando...' : 'Enviar'}
            </Button>
            <Button key='back' onClick={() => setShowCommentModal(false)}>
              Cancelar
            </Button>
          </>
        }
      >
        <h3>Detalles de transacción:</h3>
        {commentModalSotTxn && commentModalSotTxn.sotTxnId ? (
          <>
            <Row>
              <Col span={6}>
                <strong>Fecha:</strong>
              </Col>
              <Col span={18}>{commentModalSotTxn.fechaFormatted}</Col>
            </Row>
            <Row>
              <Col span={6}>
                <strong>Descripcion:</strong>
              </Col>
              <Col span={18}>{commentModalSotTxn.Descripcion}</Col>
            </Row>
            <Row>
              <Col span={6}>
                <strong>Monto:</strong>
              </Col>
              <Col span={18}>
                <FormattedUSD total={Math.abs(commentModalSotTxn.amount)} />
              </Col>
            </Row>
            {/* <Row>
              <Col span={6}>
                <strong>SotTxnId:</strong>
              </Col>
              <Col span={18}>{commentModalSotTxn.sotTxnId}</Col>
            </Row> */}
            <Input.TextArea
              rows={3}
              placeholder='Escribe tu comentario aquí'
              style={{ marginTop: 20 }}
              value={commentText} // Bind the input to the state
              onChange={(e) => setCommentText(e.target.value)} // Update state on change
            />
          </>
        ) : null}
      </Modal>

      <Content
        style={{
          margin: '0 0 0',
          overflow: 'initial',
          borderLeft: 'solid rgb(235,235,235) 1px',
          borderRight: 'solid rgb(235,235,235) 1px',
          borderTop: 'solid rgb(235,235,235) 1px',
        }}
      >
        <AffixDiv>
          <Row>
            <Col span={10} style={{ textAlign: 'left' }}>
              <div
                style={{
                  textAlign: 'left',
                  paddingTop: 40,
                  fontSize: 36,
                  fontWeight: 'bold',
                }}
              >
                Inbox
              </div>
              {/* <Refresh onClick={() => setLoading(!loading)} spin={loading} /> */}
            </Col>
            <Col span={14} style={{ textAlign: 'right' }}></Col>
          </Row>
          {/* {auth.email == 'noreplyherofacturas@gmail.com' && ( */}
          <>
            <Row
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              {/* <AccountingObligationsTable API_domain={props.API_domain} /> */}
              <AccountingObligationsTablesFetcher
                API_domain={props.API_domain}
                refresh={refresh}
                setRefresh={setRefresh}
                setShowCommentModal={setShowCommentModal}
                setCommentModalSotTxn={setCommentModalSotTxn}
              />
            </Row>
            <Divider />
          </>
          {/* )} */}
          <div>
            <AccountingInboxFetcher
              API_domain={props.API_domain}
              refresh={refresh}
              setRefresh={setRefresh}
              setShowCommentModal={setShowCommentModal}
              setCommentModalSotTxn={setCommentModalSotTxn}
            />
          </div>
        </AffixDiv>
      </Content>
    </>
  );
}

const AffixDiv = styled.div`
  background-color: white;
  padding-left: 14px;
  padding-right: 14px;
  padding-top: 14px;
  padding-bottom: 8px;
  max-width: 1400px;
  margin: auto;
`;

export { AccountingInboxView };
