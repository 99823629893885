import { Card, Col, Divider, Space, Row } from 'antd';
import { AdminAddFincaToUserForm } from './AdminAddFincaToUserForm.js';
import { AdminNewUserForm } from './AdminNewUserForm.js';
import { AdminRemoveUserFromTeam } from './AdminRemoveUserFromTeam.js';
import { AdminUnapproveReport } from './AdminUnapproveReport.js';
import { AdminPayin } from './AdminPayin.js';
import { AdminPayout } from './AdminPayout.js';
import { AdminPayinHistory } from './AdminPayinHistory.js';
import { AdminSendLoginEmailToAdmin } from './AdminSendLoginEmailToAdmin.js';
import { AdminGetTeamsFromUsername } from './AdminGetTeamsFromUsername.js';
import { AdminGetLastMessages } from './AdminGetLastMessages.js';
import { AdminGetAIMessages } from './AdminGetAIMessages.js';
import { AdminCreatePaycaddyUser } from './AdminCreatePaycaddyUser.js';
import { AdminJoinPaycaddyWithCashflow } from './AdminJoinPaycaddyWithCashflow.js';
import { AdminSendMessage } from './AdminSendMessage.js';
import { AdminPayinCompanyWallet } from './AdminPayinCompanyWallet.js';
import { AdminAutomaticReceiptMatcher } from './AdminAutomaticReceiptMatcher.js';
import { AdminCreatePaycaddyCompany } from './AdminCreatePaycaddyCompany.js';
import { AdminCreateCompanyCard } from './AdminCreateCompanyCard.js';
import { AdminGetCardInfo } from './AdminGetCardInfo.js';
import { AdminResolution } from './AdminResolution.js';
import { AdminMassUploader } from './AdminMassUploader.js';
import { AdminDGI_Docs_Upload } from './AdminDGI_Docs_Upload.js';
import { AdminGetExtractedText } from './AdminGetExtractedText.js';
import { AdminZohoConverterFE } from './AdminZohoConverterFE.js';
import { AdminZohoJournalPoster } from './AdminZohoJournalPoster.js';
import { AdminNewUserFormExistingPhone } from './AdminNewUserFormExistingPhone.js';
import { AdminZohoCorrectDateReturner } from './AdminZohoCorrectDateReturner.js';
import { AdminECBGtoZohoConvertorInput } from './AdminECBGtoZohoConvertorInput.js';
import { AdminZohoVentasRemoveBlankRows } from './AdminZohoVentasRemoveBlankRows.js';
import { AdminSyncHeroCardAndSot } from './AdminSyncHeroCardAndSot.js';
import { AdminECBGclassifierWzohoAccounts } from './AdminECBGclassifierWzohoAccounts.js';
import { AdminCalculateITBMS } from './AdminCalculateITBMS.js';
import { AdminSOTandHeroGastosMatcher } from './AdminSOTandHeroGastosMatcher.js';
import { AdminGetUserLoginLink } from './AdminGetUserLoginLink.js';
import { AdminCreateAccountingClient } from './AdminCreateAccountingClient.js';
import { AdminCreateSOT } from './AdminCreateSOT.js';
import { AdminGetLatestDGIStatement } from './AdminGetLatestDGIStatement.js';
import { AdminZohoFetchNewAccounts } from './AdminZohoFetchNewAccounts.js';
import { AdminCreateAccountingObligationEnum } from './AdminCreateAccountingObligationEnum.js';
import { AdminSendObligationReminder } from './AdminSendObligationReminder.js';
import { AdminCreateAccountingObligation } from './AdminCreateAccountingObligation.js';
import { AdminEditObligation } from './AdminEditObligation.js';
import { AdminCreateCompanyWallet } from './AdminCreateCompanyWallet.js';
import { AdminPayDGI } from './AdminPayDGI.js';
import { AdminDisableUser } from './AdminDisableUser.js';
import { AdminFetchDGIFE } from './AdminFetchDGIFE.js';
import { AdminInvuToZoho } from './AdminInvuToZoho.js';
import { NewAdminInvuToZoho } from './NewAdminInvuToZoho.js';
import { AdminPayrollToZoho } from './AdminPayrollToZoho.js';
import { AdminCreateCompany } from './AdminCreateCompany.js';
import { AdminEditUserCompany } from './AdminEditUserCompany.js';
import { AdminJoinAccountingClientWithCompany } from './AdminJoinAccountingClientWithCompany.js';
import { AdminSendAllPendingObligationReminders } from './AdminSendAllPendingObligationReminders.js';
import { AdminApifyPayBG } from './AdminApifyPayBG.js';
import { AdminPayoutCompanyWallet } from './AdminPayoutCompanyWallet.js';
import { AdminGetAccountingClientUserLoginLink } from './AdminGetAccountingClientUserLoginLink.js';
import { AdminCreateClientConfig } from './AdminCreateClientConfig.js';
import { AdminViewClientConfig } from './AdminViewClientConfig.js';
import { AdminRunBGApifyActor } from './AdminRunBGApifyActor.js';
import { AdminOnboardingLinkCreator } from './AdminOnboardingLinkCreator.js';

function AdminView(props) {
  return (
    <Card
      style={{
        margin: '16px',
        borderRadius: '10px',
        boxShadow: '2px 2px 15px 8px rgb(240,240,240)',
        padding: '20px',
      }}
    >
      <Space direction='vertical' size='large'>
        <h2>Zoho Tools</h2>
        <Row gutter={16} justify='center'>
          <Col>
            <AdminECBGclassifierWzohoAccounts API_domain={props.API_domain} />
          </Col>
          <Col>
            <AdminECBGtoZohoConvertorInput API_domain={props.API_domain} />
          </Col>
          <Col>
            <AdminZohoConverterFE API_domain={props.API_domain} />
          </Col>
        </Row>
        <Row gutter={16} justify='center'>
          <Col>
            <AdminZohoJournalPoster API_domain={props.API_domain} />
          </Col>
          <Col>
            <AdminSOTandHeroGastosMatcher API_domain={props.API_domain} />
          </Col>
        </Row>
        <Row gutter={16} justify='center'>
          <Col>
            <AdminZohoVentasRemoveBlankRows API_domain={props.API_domain} />
          </Col>
          <Col>
            <AdminZohoCorrectDateReturner API_domain={props.API_domain} />
          </Col>
          <Col>
            <AdminGetLatestDGIStatement API_domain={props.API_domain} />
          </Col>
        </Row>
        <Row gutter={16} justify='center'>
          <Col>
            <AdminZohoFetchNewAccounts API_domain={props.API_domain} />
          </Col>
          <Col>
            <AdminInvuToZoho API_domain={props.API_domain} />
          </Col>
          <Col>
            <NewAdminInvuToZoho API_domain={props.API_domain} />
          </Col>
          <Col>
            <AdminPayrollToZoho API_domain={props.API_domain} />
          </Col>
        </Row>

        <Divider />

        <h2>User Management</h2>
        <Row gutter={16} justify='center'>
          <Col>
            <AdminNewUserForm API_domain={props.API_domain} />
          </Col>
          <Col>
            <AdminNewUserFormExistingPhone API_domain={props.API_domain} />
          </Col>
          <Col>
            <AdminAddFincaToUserForm API_domain={props.API_domain} />
          </Col>
          <Col>
            <AdminRemoveUserFromTeam API_domain={props.API_domain} />
          </Col>
        </Row>
        <Row gutter={16} justify='center'>
          <Col>
            Get user teams:
            <AdminGetTeamsFromUsername API_domain={props.API_domain} />
          </Col>
          <Col>
            Login as user:
            <AdminGetUserLoginLink API_domain={props.API_domain} />
          </Col>
        </Row>
        <Row gutter={16} justify='center'>
          <Col>
            <AdminCreateAccountingClient API_domain={props.API_domain} />
          </Col>
          <Col>
            <AdminCreateSOT API_domain={props.API_domain} />
          </Col>
          <Col>
            <AdminCreateClientConfig API_domain={props.API_domain} />
          </Col>
          <Col>
            <AdminViewClientConfig API_domain={props.API_domain} />
          </Col>
          <Col>
            <AdminCreateAccountingObligationEnum
              API_domain={props.API_domain}
            />
          </Col>
          <Col>
            <AdminCreateAccountingObligation API_domain={props.API_domain} />
          </Col>
        </Row>
        <Row gutter={16} justify='center'>
          <Col>
            <AdminSyncHeroCardAndSot API_domain={props.API_domain} />
          </Col>
          <Col>
            <AdminCreateCompany API_domain={props.API_domain} />
          </Col>
          <Col>
            <AdminEditUserCompany API_domain={props.API_domain} />
          </Col>
          <Col>
            <AdminJoinAccountingClientWithCompany
              API_domain={props.API_domain}
            />
          </Col>
        </Row>
        <Row gutter={16} justify='center'>
          <AdminOnboardingLinkCreator API_domain={props.API_domain} />
        </Row>

        <Divider />

        <h2>Paycaddy Tools</h2>

        <Row gutter={40} justify='center'>
          <Col>
            <AdminPayinHistory API_domain={props.API_domain} />
          </Col>
          <Col>
            <AdminPayin API_domain={props.API_domain} />
          </Col>
          <Col>
            <AdminPayinCompanyWallet API_domain={props.API_domain} />
          </Col>
          <Col>
            <AdminPayout API_domain={props.API_domain} />
          </Col>
          <Col>
            <AdminPayoutCompanyWallet API_domain={props.API_domain} />
          </Col>
          <Col>
            <AdminCreatePaycaddyUser API_domain={props.API_domain} />
          </Col>
        </Row>

        <Row gutter={40} justify='center'>
          <Col>
            <AdminCreatePaycaddyCompany API_domain={props.API_domain} />
          </Col>
          <Col>
            <AdminCreateCompanyWallet API_domain={props.API_domain} />
          </Col>
          <Col>
            <AdminCreateCompanyCard API_domain={props.API_domain} />
          </Col>
          <Col>
            <AdminGetCardInfo API_domain={props.API_domain} />
          </Col>
          <Col>
            <AdminResolution API_domain={props.API_domain} />
          </Col>
        </Row>

        <Divider />

        <h2>Admin Tools</h2>
        <Row gutter={16} justify='center'>
          <Col>
            <AdminMassUploader API_domain={props.API_domain} />
          </Col>
          <Col>
            <AdminDGI_Docs_Upload API_domain={props.API_domain} />
          </Col>
          <Col>
            <AdminGetExtractedText API_domain={props.API_domain} />
          </Col>
          <Col>
            Automatically match receipts 💳:
            <AdminAutomaticReceiptMatcher API_domain={props.API_domain} />
          </Col>
          <Col>
            <AdminDisableUser API_domain={props.API_domain} />
          </Col>
          <Col>
            <AdminCalculateITBMS API_domain={props.API_domain} />
          </Col>
        </Row>
        <Row gutter={16} justify='center'>
          <Col>
            <AdminEditObligation API_domain={props.API_domain} />
          </Col>
          <Col>
            <AdminPayDGI API_domain={props.API_domain} />
          </Col>
          <Col>
            <AdminFetchDGIFE API_domain={props.API_domain} />
          </Col>
          <Col>
            <AdminApifyPayBG API_domain={props.API_domain} />
          </Col>
          <Col>
            <AdminUnapproveReport API_domain={props.API_domain} />
          </Col>
          <Col>
            <AdminRunBGApifyActor API_domain={props.API_domain} />
          </Col>
        </Row>
        <Divider />

        <h2>Messaging Tools</h2>
        <Row gutter={16} justify='center'>
          <Col>
            Get latest messages:
            <AdminGetLastMessages API_domain={props.API_domain} />
          </Col>
          <Col>
            Make bot send message to:
            <AdminSendMessage API_domain={props.API_domain} />
          </Col>
          {/* <Col>
            Get AI messages:
            <AdminGetAIMessages API_domain={props.API_domain} />
          </Col> */}
          <Col>
            <AdminSendObligationReminder API_domain={props.API_domain} />
          </Col>
          <Col>
            <AdminSendAllPendingObligationReminders
              API_domain={props.API_domain}
            />
          </Col>
        </Row>
      </Space>
    </Card>
  );
}

export { AdminView };
