import React from 'react';
import { Col, Row } from 'antd';
import { FormattedUSD } from '../FormattedUSD.js';
import { formatDateSpanishLong } from '../../utils';

function PayrollSlip(props) {
  console.log(props);
  let accountingClientLogoSrc =
    'accounting_client_logo_' +
    props.selectedEmployee.accounting_clients_id +
    '.png';
  // console.log('loaded payroll slip');
  return (
    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
      {props.selectedEmployee ? (
        <div id={props.id} style={{ width: '100%' }}>
          <Row>
            <Col span={12}>
              <h2 style={{ marginBottom: 10 }}>
                Comprobante de Planilla:{' '}
                {formatDateSpanishLong(props.selectedEmployee.payable_date)}{' '}
              </h2>
              <div style={{ fontSize: 18, marginTop: 20 }}>
                <b>{props.selectedEmployee.name}</b>
              </div>
              <div style={{ marginBottom: 10 }}>
                {props.selectedEmployee.gov_id}
              </div>
            </Col>
            <Col span={12} style={{ textAlign: 'right' }}>
              <img src={accountingClientLogoSrc} style={{ maxHeight: 70 }} />
              <img src='heroLogoTransparent.png' style={{ maxHeight: 70 }} />
            </Col>
          </Row>
          <Row>
            {props.selectedEmployee.comments && (
              <div style={{ marginBottom: 16 }}>
                <b>{props.selectedEmployee.comments}</b>
              </div>
            )}
          </Row>
          <Row>
            {props.selectedEmployee.notes && (
              <div style={{ marginBottom: 16 }}>
                Notas: <b>{props.selectedEmployee.notes}</b>
              </div>
            )}
          </Row>

          <Row>
            <Col span={8}>
              <div
                style={{
                  padding: 20,
                  margin: 10,
                  border: '2px solid',
                  height: 200,
                  borderRadius: 6,
                }}
              >
                <h3>Ingresos</h3>
                <Row>
                  <Col span={16} style={{ fontWeight: 700 }}>
                    <div>Salario Regular</div>
                    {props.selectedEmployee.benefit_production > 0 && (
                      <div>Prima de Producción</div>
                    )}
                    {props.selectedEmployee.benefit_fuel > 0 && (
                      <div>Combustible</div>
                    )}
                    {props.selectedEmployee.benefit_representation_expenses >
                      0 && <div>Gastos de Rep.</div>}
                    {props.selectedEmployee.overtime > 0 && (
                      <div>Tiempo Extra</div>
                    )}
                  </Col>
                  <Col span={8} style={{ textAlign: 'right' }}>
                    <div>
                      <FormattedUSD
                        total={props.selectedEmployee.biweekly_salary}
                      />
                    </div>
                    {props.selectedEmployee.benefit_production > 0 && (
                      <div>
                        <FormattedUSD
                          total={props.selectedEmployee.benefit_production}
                        />
                      </div>
                    )}
                    {props.selectedEmployee.benefit_fuel > 0 && (
                      <div>
                        <FormattedUSD
                          total={props.selectedEmployee.benefit_fuel}
                        />
                      </div>
                    )}
                    {props.selectedEmployee.benefit_representation_expenses >
                      0 && (
                      <div>
                        <FormattedUSD
                          total={
                            props.selectedEmployee
                              .benefit_representation_expenses
                          }
                        />
                      </div>
                    )}
                    {props.selectedEmployee.overtime > 0 && (
                      <div>
                        <FormattedUSD total={props.selectedEmployee.overtime} />
                      </div>
                    )}
                  </Col>
                </Row>
                <Row
                  style={{
                    borderTop: 'solid 1px black',
                    marginTop: 10,
                    color: 'green',
                    fontWeight: 700,
                  }}
                >
                  <Col span={16}>
                    <div>Total de Ingresos</div>
                  </Col>
                  <Col span={8} style={{ textAlign: 'right' }}>
                    <FormattedUSD
                      total={props.selectedEmployee.payroll_gross}
                    />
                  </Col>
                </Row>
              </div>
            </Col>
            <Col span={1}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  height: '100%',
                }}
              >
                -
              </div>
            </Col>
            <Col span={8}>
              <div
                style={{
                  padding: 20,
                  margin: 10,
                  border: '2px solid',
                  height: 200,
                  borderRadius: 6,
                }}
              >
                <h3>Deducciones</h3>
                <Row>
                  <Col span={16} style={{ fontWeight: 700 }}>
                    <div>Seguro Social 9.75%</div>
                    <div>Seguro Educativo 1.25%</div>
                    <div>ISR</div>
                    {props.selectedEmployee.deduction_attendance > 0 && (
                      <div>Ausencias</div>
                    )}
                    {props.selectedEmployee.deduction_direct_discount > 0 && (
                      <div>Descuento Directo</div>
                    )}
                    {props.selectedEmployee.advance_deductions > 0 && (
                      <div>Descuento de Adelantos</div>
                    )}
                  </Col>
                  <Col span={8} style={{ textAlign: 'right' }}>
                    <div>
                      <FormattedUSD
                        total={props.selectedEmployee.deduction_ss}
                      />
                    </div>
                    <div>
                      <FormattedUSD
                        total={props.selectedEmployee.deduction_se}
                      />
                    </div>
                    <div>
                      <FormattedUSD
                        total={props.selectedEmployee.deduction_isr}
                      />
                    </div>
                    {props.selectedEmployee.deduction_attendance > 0 && (
                      <div>
                        <FormattedUSD
                          total={props.selectedEmployee.deduction_attendance}
                        />
                      </div>
                    )}
                    {props.selectedEmployee.deduction_direct_discount > 0 && (
                      <div>
                        <FormattedUSD
                          total={
                            props.selectedEmployee.deduction_direct_discount
                          }
                        />
                      </div>
                    )}
                    {props.selectedEmployee.advance_deductions > 0 && (
                      <div>
                        <FormattedUSD
                          total={props.selectedEmployee.advance_deductions}
                        />
                      </div>
                    )}
                  </Col>
                </Row>
                <Row
                  style={{
                    borderTop: 'solid 1px black',
                    marginTop: 10,
                    color: 'red',
                    fontWeight: 700,
                  }}
                >
                  <Col span={16}>
                    <div>Total de Deducciones</div>
                  </Col>
                  <Col span={8} style={{ textAlign: 'right' }}>
                    <FormattedUSD
                      total={props.selectedEmployee.deduction_total}
                    />
                  </Col>
                </Row>
              </div>
            </Col>
            <Col span={1}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  height: '100%',
                }}
              >
                =
              </div>
            </Col>
            <Col span={6}>
              <div
                style={{
                  padding: 20,
                  margin: 10,
                  border: '2px solid',
                  height: 200,
                  borderRadius: 6,
                }}
              >
                <h3>Salario Neto a Recibir</h3>
                <div
                  style={{
                    color: 'rgb(65,130,239',
                    fontWeight: 700,
                    fontSize: 20,
                    display: 'flex',
                    alignItems: 'center',
                    height: '50%',
                  }}
                >
                  <FormattedUSD total={props.selectedEmployee.payroll_net} />
                </div>
              </div>
            </Col>
          </Row>
        </div>
      ) : null}
    </div>
  );
}

export { PayrollSlip };
