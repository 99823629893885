import React, { useContext, useEffect, useState } from 'react';
import { Select } from 'antd';
import axios from 'axios';
import { authContext } from '../ProvideAuth.js';

const { Option } = Select;

const SOTSelect = (props) => {
  const [options, setOptions] = useState([]);
  const auth = useContext(authContext);

  const fetchSourcesOfTruth = () => {
    axios({
      method: 'get',
      url: props.API_domain + 'getSourcesOfTruth',
      auth: {
        username: auth.email, // Ensure 'auth' object is available in this context
        password: auth.token,
      },
    })
      .then((response) => {
        let options = response.data;
        // options.sort((a, b) => {
        options.sort((a, b) =>
          a.accounting_client_name.localeCompare(b.accounting_client_name)
        );
        setOptions(options);
      })
      .catch((error) => {
        console.error('Error fetching sources of truth:', error);
      });
  };

  useEffect(() => {
    fetchSourcesOfTruth();
  }, []);

  const handleChange = (value) => {
    props.setSelectedSot(options.find((opt) => opt.value === value));
  };

  return (
    <Select
      style={{ width: 420, marginLeft: 10 }}
      placeholder='Escoger fuente de verdad'
      onChange={handleChange}
      value={props.selectedSot.sources_of_truth_id}
      showSearch
      filterOption={(input, option) =>
        options
          .find((opt) => opt.value === option.value)
          .accounting_client_name.toLowerCase()
          .includes(input.toLowerCase()) ||
        options
          .find((opt) => opt.value === option.value)
          .source_name.toLowerCase()
          .includes(input.toLowerCase()) ||
        options
          .find((opt) => opt.value === option.value)
          .source_zoho_name.toLowerCase()
          .includes(input.toLowerCase())
      }
    >
      {options.map((opt) => (
        <Option key={opt.value} value={opt.value}>
          <>
            <b>{opt.accounting_client_name}</b>{' '}
            <span style={{ color: 'grey', marginLeft: 4, marginRight: 4 }}>
              |
            </span>{' '}
            {opt.source_zoho_name}{' '}
            <span style={{ color: 'grey', marginLeft: 4, marginRight: 8 }}>
              |
            </span>
            {opt.source_name}
          </>
        </Option>
      ))}
    </Select>
  );
};

export { SOTSelect };
