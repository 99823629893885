import { useContext } from 'react';
import { authContext } from '../ProvideAuth.js';
import { Route, Redirect } from 'react-router-dom';

// A wrapper for <Route> that redirects to the login
// screen if you're not yet authenticated.

function DummyRedirect() {
  window.location.href = 'https://try.herofacturas.com/';
  return <></>;
}
function LoggedInRoute({ children, ...rest }) {
  let auth = useContext(authContext);
  return (
    <Route
      {...rest}
      render={({ location }) =>
        auth.email ? (
          <Redirect
            to={{
              pathname: '/expenses',
              state: { from: location },
            }}
          />
        ) : children ? (
          children
        ) : (
          <DummyRedirect />
        )
      }
    />
  );
}

export { LoggedInRoute };
