import { Button, Card, Col, Divider, Row, Tooltip } from 'antd';
import { SettingOutlined } from '@ant-design/icons';
import React, { useContext, useState } from 'react';
import { ConfigMembersTable } from './ConfigMembersTable.js';
import { ConfigCategoryManager } from './ConfigCategoryManager.js';
import { ConfigPaymentMethodManager } from './ConfigPaymentMethodManager.js';
import { ConfigRenameTeamModal } from './ConfigRenameTeamModal.js';
import { authContext } from '../ProvideAuth.js';
import {
  PlusOutlined,
  CaretDownOutlined,
  CaretUpOutlined,
} from '@ant-design/icons';
import styled from 'styled-components';

function ConfigTeamCard(props) {
  const auth = useContext(authContext);
  const [renameTeam, setRenameTeam] = useState();
  const [showFullCard, setShowFullCard] = useState(props.fullCard);
  const onSubmit = () => {
    props.setSubmittedToggle(!props.submittedToggle);
  };
  return (
    <HoverableCard
      showFullCard={showFullCard}
      style={{
        margin: 10,
        borderRadius: '10px',
        boxShadow: '2px 2px 15px 8px rgb(240,240,240)',
      }}
      title={
        <Row onClick={() => setShowFullCard(!showFullCard)}>
          <Col span={6}></Col>
          <Col span={12}>
            {props.team}
            {showFullCard ? (
              <Button
                style={{ border: 'none', color: 'grey' }}
                onClick={(event) => {
                  event.stopPropagation();
                  setRenameTeam(true);
                }}
              >
                <Tooltip title='Configurar Equipo'>
                  <SettingOutlined />
                </Tooltip>
              </Button>
            ) : (
              ' '
            )}
          </Col>
          <Col span={6} style={{ textAlign: 'right' }}>
            <Button
              type={'text'}
              onClick={() => setShowFullCard(!showFullCard)}
            >
              {showFullCard ? <CaretUpOutlined /> : <CaretDownOutlined />}
            </Button>
          </Col>
        </Row>
      }
      onClick={() => (showFullCard ? null : setShowFullCard(!showFullCard))}
    >
      {renameTeam && (
        <ConfigRenameTeamModal
          visible={true}
          title={'Configurar Equipo'}
          handleCancel={() => setRenameTeam(null)}
          handleOk={() => setRenameTeam(null)}
          onSubmit={onSubmit}
          API_domain={props.API_domain}
          API_endPoint='renameTeam'
          team={props.team}
          isOwner={props.isOwner}
        />
      )}
      {showFullCard ? (
        <>
          <h3 style={{ textAlign: 'left' }}>Roles de usuarios</h3>
          <ConfigMembersTable
            API_domain={props.API_domain}
            submittedToggle={props.submittedToggle}
            openModal={props.setSelectedMember}
            team={props.team}
            setSelectedTeam={props.setSelectedTeam}
          />
          <div style={{ marginTop: '20px' }}>
            <Button
              type='primary'
              onClick={() => {
                props.setSelectedMember(true);
                props.setAPI_endPoint('addUserToTeam');
                props.setSelectedTeam(props.team);
              }}
            >
              <PlusOutlined /> Agregar Usuario a Equipo
            </Button>
          </div>
          <Divider />
          <div style={{ marginTop: '20px' }}>
            <h3 style={{ textAlign: 'left' }}>Categorías</h3>
            <ConfigCategoryManager
              API_domain={props.API_domain}
              team={props.team}
            />
          </div>
          {(auth.email === 'noreplyherofacturas@gmail.com' ||
            auth.email === 'alvarososa123@gmail.com' ||
            auth.email === 'jarol@herofacturas.com') && (
            <>
              <Divider />
              <div style={{ marginTop: '20px' }}>
                <h3 style={{ textAlign: 'left' }}>Formas de pago</h3>
                <ConfigPaymentMethodManager
                  API_domain={props.API_domain}
                  team={props.team}
                />
              </div>
            </>
          )}
        </>
      ) : null}
    </HoverableCard>
  );
}

const HoverableCard = styled(Card)`
  cursor: ${(props) => (props.showFullCard ? 'default' : 'pointer')};

  &:hover {
    background-color: ${(props) =>
      props.showFullCard ? 'white' : 'rgba(237, 240, 254, 0.4)'};
  }
`;

export { ConfigTeamCard };
