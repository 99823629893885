import React, { useState } from 'react';
import { Form, Input, Button, Row, Col, message } from 'antd';
import { CopyOutlined } from '@ant-design/icons';

const DGIIntegration = (props) => {
  const [ruc, setRuc] = useState(props.dgiCredentials.login_info.ruc || '');
  const [nit, setNit] = useState(props.dgiCredentials.login_info.nit || '');
  const [loading, setLoading] = useState(false); // State to manage the loading status

  const handleSave = async () => {
    setLoading(true); // Start loading

    try {
      const updatedCredentials = {
        accounting_client_id: props.dgiCredentials.accounting_client_id,
        id: props.dgiCredentials.id, // Unique ID for the DGI configuration
        site: 'dgi',
        login_info: {
          ruc,
          nit,
        },
      };

      // Send only the relevant credentials to the backend
      await props.updateAccountingClientConfig(updatedCredentials);
      message.success('Actualización exitosa');
    } catch (error) {
      console.error('Error during update:', error);
      message.error('Error actualizando');
    } finally {
      setLoading(false); // Stop loading
    }
  };

  return (
    <div style={{ marginBottom: 32, marginTop: 32 }}>
      <h3 style={{ marginLeft: 4, textAlign: 'left' }}>DGI</h3>
      <Form.Item label='RUC' style={{ marginLeft: 96 }}>
        <Row>
          <Col span={22}>
            <Input value={ruc} onChange={(e) => setRuc(e.target.value)} />
          </Col>
          <Col span={2}>
            <Button
              icon={<CopyOutlined />}
              type='text'
              style={{ color: 'grey' }}
              onClick={() => props.copyToClipboard(ruc)}
            />
          </Col>
        </Row>
      </Form.Item>
      <Form.Item label='NIT' style={{ marginLeft: 96 }}>
        <Row>
          <Col span={22}>
            <Input value={nit} onChange={(e) => setNit(e.target.value)} />
          </Col>
          <Col span={2}>
            <Button
              icon={<CopyOutlined />}
              type='text'
              style={{ color: 'grey' }}
              onClick={() => props.copyToClipboard(nit)}
            />
          </Col>
        </Row>
      </Form.Item>
      <div style={{ textAlign: 'left', marginLeft: 96 }}>
        <Button
          loading={loading}
          type='primary'
          onClick={handleSave}
          style={{ marginRight: 16 }}
        >
          Guardar
        </Button>
        <Button
          type='secondary'
          onClick={() => console.log('validating')}
          style={{
            backgroundColor: 'hsl(0, 0%, 95%)',
            borderColor: 'hsl(0, 0%, 95%)',
          }}
          disabled
        >
          Validar DGI
          {/* {props.renderValidationIcon(props.dgiCredentials.dgi_last_verify)} */}{' '}
          ✅
        </Button>
      </div>
    </div>
  );
};

export default DGIIntegration;
