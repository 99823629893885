import React, { useEffect, useState } from 'react';
import { Button } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import {
  get_zoho_manual_entries_from_csv_blob,
  downloadBlob,
  dateTransformer,
} from '../utils.js';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

const BankZohoDownloader = (props) => {
  const [transformedData, setTransformedData] = useState([]);

  // console.log(data);
  // 1. fecha 2. credit_account 3. debit_account 4. notas 5. monto
  useEffect(() => {
    const accountIdsToNameMap = props.accountingAccountOptions.reduce(
      (acc, curr) => {
        acc[curr.id] = curr.name;
        return acc;
      },
      {}
    );
    setTransformedData(
      props.data.map((row) => {
        const accountName = row.flagged ? 'Cuenta por revisar' : accountIdsToNameMap[row['accounting_account_id']];
        if (row['amount'] < 0) {
          return [
            dateTransformer(row['Fecha']),
            props.selectedSot.source_zoho_name,
            accountName,
            row['description'],
            Math.abs(row['amount']),
          ];
        } else {
          return [
            dateTransformer(row['Fecha']),
            accountName,
            props.selectedSot.source_zoho_name,
            row['description'],
            Math.abs(row['amount']),
          ];
        }
      })
    );
  }, [props.data, props.accountingAccountOptions]);

  const exportToCSV = () => {
    const header = [['Fecha', 'Cuenta de crédito', 'Cuenta de débito', 'Notas', 'Monto']];
    const zoho_manual_entries_blob =
      get_zoho_manual_entries_from_csv_blob(header.concat(transformedData));
    downloadBlob(zoho_manual_entries_blob, 'zoho_manual_entries.csv');
  };

  return (
    <Button icon={<DownloadOutlined />} onClick={() => exportToCSV()}>
      Zoho
    </Button>
  );
};

export { BankZohoDownloader };
