import {
  CheckCircleFilled,
  InfoCircleOutlined,
  LoadingOutlined,
} from '@ant-design/icons';
import { Table, Tooltip } from 'antd';
import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { authContext } from '../ProvideAuth.js';

function formatPhoneNumber(input) {
  let countryFlag;
  let phoneNumber;

  if (input.startsWith('whatsapp:+507')) {
    countryFlag = '🇵🇦';
    phoneNumber = input.replace('whatsapp:+507', '');
  } else if (input.startsWith('whatsapp:+57')) {
    countryFlag = '🇨🇴';
    phoneNumber = input.replace('whatsapp:+57', '');
  } else if (input.startsWith('whatsapp:+1')) {
    countryFlag = '🇺🇸';
    phoneNumber = input.replace('whatsapp:+1', '');
  } else if (input.startsWith('whatsapp:+34')) {
    countryFlag = '🇪🇸';
    phoneNumber = input.replace('whatsapp:+34', '');
  } else if (input.startsWith('whatsapp:+55')) {
    countryFlag = '🇧🇷';
    phoneNumber = input.replace('whatsapp:+55', '');
  } else if (input.startsWith('whatsapp:+971')) {
    countryFlag = '🇦🇪';
    phoneNumber = input.replace('whatsapp:+971', '');
  } else if (input.startsWith('whatsapp:+58')) {
    countryFlag = '🇻🇪';
    phoneNumber = input.replace('whatsapp:+58', '');
  } else if (input.startsWith('whatsapp:+39')) {
    countryFlag = '🇮🇹';
    phoneNumber = input.replace('whatsapp:+39', '');
  } else if (input.startsWith('whatsapp:+44')) {
    countryFlag = '🇬🇧';
    phoneNumber = input.replace('whatsapp:+44', '');
  } else {
    return 'Número inválido';
  }

  return countryFlag + ' ' + phoneNumber;
}

function ConfigMembersTable(props) {
  const [error, setError] = useState(null);
  const [isLoaded, setLoaded] = useState(false);
  const [items, setItems] = useState('');
  const auth = useContext(authContext);
  const usersColumns = [
    {
      title: 'Nombre',
      dataIndex: 'name',
      key: 'name',
      render: (name, record) => {
        return (
          <Tooltip
            placement='right'
            title={
              <>
                <div>
                  {record.phone.includes('phone')
                    ? ''
                    : formatPhoneNumber(record.phone)}
                </div>
                <div>{record.email}</div>
              </>
            }
          >
            <span>{name}</span>
          </Tooltip>
        );
      },
    },
    // {
    //   title: 'Celular',
    //   dataIndex: 'phone',
    //   key: 'phone',
    //   width: 160,
    //   render: (phone) => {
    //     if (phone.includes('phone')) {
    //       return '';
    //     }
    //     return formatPhoneNumber(phone);
    //   },
    // },
    // {
    //   title: 'Email',
    //   dataIndex: 'email',
    //   key: 'email',
    //   width: 240,
    //   render: (email) => {
    //     if (email.includes('email')) {
    //       return '';
    //     }
    //     return email;
    //   },
    // },
    {
      title: (
        <>
          Creación de gastos{'  '}
          <Tooltip title='Si el usuario puede ingresar gastos por WhatsApp'>
            <InfoCircleOutlined />
          </Tooltip>
        </>
      ),
      dataIndex: 'expense_creator',
      key: 'expense_creator',
      align: 'center',
      width: 140,
      render: (expense_creator) => {
        if (expense_creator == true) {
          return <CheckCircleFilled style={{ color: 'green' }} />;
        }
        return '';
      },
    },
    {
      title: (
        <>
          Aprobación de reportes{'  '}
          <Tooltip title='Si el usuario puede aprobar reportes'>
            <InfoCircleOutlined />
          </Tooltip>
        </>
      ),
      dataIndex: 'approver',
      key: 'approver',
      align: 'center',
      width: 140,
      render: (approver) => {
        if (approver == true) {
          return <CheckCircleFilled style={{ color: 'green' }} />;
        }
        return '';
      },
    },
    {
      title: (
        <>
          Email de creación{'  '}
          <Tooltip title='Si el usuario quiere recibir emails cuando reportes son creados'>
            <InfoCircleOutlined />
          </Tooltip>
        </>
      ),
      dataIndex: 'email_on_creation',
      key: 'email_on_creation',
      align: 'center',
      width: 140,
      render: (email_on_creation) => {
        if (email_on_creation == true) {
          return <CheckCircleFilled style={{ color: 'green' }} />;
        }
        return '';
      },
    },
    {
      title: (
        <>
          Email de aprobación{'  '}
          <Tooltip title='Si el usuario quiere recibir emails cuando reportes son aprobados'>
            <InfoCircleOutlined />
          </Tooltip>
        </>
      ),
      dataIndex: 'email_on_approval',
      key: 'email_on_approval',
      align: 'center',
      width: 140,
      render: (email_on_approval) => {
        if (email_on_approval == true) {
          return <CheckCircleFilled style={{ color: 'green' }} />;
        }
        return '';
      },
    },
    {
      title: (
        <>
          Email de reembolso{'  '}
          <Tooltip
            title='Si el usuario quiere recibir emails cuando reportes son reembolsados'
            placement='topRight'
          >
            <InfoCircleOutlined />
          </Tooltip>
        </>
      ),
      dataIndex: 'email_on_reimbursement',
      key: 'email_on_reimbursement',
      align: 'center',
      width: 140,
      render: (email_on_reimbursement) => {
        if (email_on_reimbursement == true) {
          return <CheckCircleFilled style={{ color: 'green' }} />;
        }
        return '';
      },
    },
  ];

  useEffect(() => {
    setLoaded(false);
    getUsers();
  }, [props.submittedToggle]);

  const getUsers = () => {
    axios({
      method: 'post',
      url: props.API_domain + 'getUsers',
      auth: {
        username: auth.email,
        password: auth.token,
      },
      data: {
        team: props.team,
      },
    })
      .then((response) => {
        setLoaded(true);
        setItems(response.data);
      })
      .catch((response) => {
        setLoaded(true);
        setError(true);
      });
  };

  if (error) {
    return <div>Error: {error.message}</div>;
  } else if (!isLoaded) {
    return <LoadingDiv>{antIcon}</LoadingDiv>;
  } else {
    return (
      <>
        <Table
          onRow={(record) => {
            return {
              onClick: () => {
                props.openModal(record);
                props.setSelectedTeam(props.team);
              },
            };
          }}
          dataSource={items}
          columns={usersColumns}
          pagination={false}
          rowClassName={() => {
            return 'cursor-pointer';
          }}
        />
      </>
    );
  }
}

const antIcon = <LoadingOutlined style={{ fontSize: 50 }} spin />;
const LoadingDiv = styled.div`
  margin-top: 100px;
  margin-bottom: 100px;
`;

export { ConfigMembersTable };
