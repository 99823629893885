import React, { useState } from 'react';
import styled from 'styled-components/macro';
import { Form, Row } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import axios from 'axios';
import VerificationInput from 'react-verification-input';
import '../otc.css';

function LoginFormFeedback(props) {
  let [helpMessage, setHelpMessage] = useState(null);
  let [loading, setLoading] = useState(false);
  const onFinish = (values) => {
    setLoading(true);
    axios({
      method: 'post',
      url: props.API_domain + 'verifyOTC',
      data: { phone_no: props.phone_no, otc: values.code },
    })
      .then((response) => {
        setLoading(false);
        console.log(response);
        window.location.replace(response.data);
      })
      .catch(function (response) {
        setLoading(false);
        const errorMessage =
          'Código inválido. Los códigos expiran después de 15 minutos'; // todo: refine this
        setHelpMessage(errorMessage);
      });
  };

  if (props.loginSent) {
    return (
      <FeedbackDiv>
        {props.loginChoice === 'email' ? (
          <>
            <div>Gracias! Revisa tu correo</div>
            <EmailDiv>{props.email}</EmailDiv>
            <div>para instrucciones de cómo entrar.</div>
            <GreyDiv>Recuerda revisar tu folder de spam.</GreyDiv>
          </>
        ) : (
          <>
            <div>
              Gracias! Ingresa aquí el código que te enviamos por WhatsApp:
            </div>
            <Form
              name='otcForm'
              // initialValues={{
              //   remember: true,
              // }}
              onFinish={onFinish}
              // onFinishFailed={onFinishFailed}
            >
              <Row style={{ marginTop: 10 }}>
                <Form.Item
                  name='code'
                  rules={[
                    {
                      required: true,
                      message: 'Por favor ingresa el código',
                    },
                  ]}
                  help={helpMessage}
                >
                  <VerificationInput
                    length={4}
                    autoFocus={true}
                    classNames={{
                      container: 'container',
                      character: 'character',
                      characterInactive: 'character--inactive',
                      characterSelected: 'character--selected',
                    }}
                    onChange={(value) => {
                      if (value.length === 4) {
                        onFinish({ code: value });
                      }
                    }}
                  />
                </Form.Item>
              </Row>
            </Form>
          </>
        )}
      </FeedbackDiv>
    );
  } else {
    return <LoadingDiv>{antIcon}</LoadingDiv>;
  }
}

const styles = {
  '.character': {
    border: 'none',
    fontSize: '20px',
    borderRadius: '8px',
  },
};

const FeedbackDiv = styled.div`
  position: absolute;
  top: 70%;
  left: 50%;
  transform: translate(-50%, -70%);
  text-align: left;
  color: rgb(45, 45, 45);
`;

const EmailDiv = styled.div`
  font-weight: bold;
`;

const GreyDiv = styled.div`
  margin-top: 16px;
  color: grey;
`;

const antIcon = <LoadingOutlined style={{ fontSize: 44 }} spin />;
const LoadingDiv = styled.div`
  margin-top: 25px;
`;

export { LoginFormFeedback };
