import React, { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { CheckCircleOutlined, LoadingOutlined } from '@ant-design/icons';
import { Button, Layout, Typography, message } from 'antd';
import styled from 'styled-components/macro';
import 'jspdf-autotable';
import { ExistingPayrollTableContainer } from './Hr_components/ExistingPayrollTableContainer';
import axios from 'axios';
import { authContext } from '../ProvideAuth';

const { Content } = Layout;
const { Title } = Typography;

const PayrollApprovalView = ({ API_domain }) => {
  const auth = useContext(authContext);
  const location = useLocation();
  const [updateSuccess, setUpdateSuccess] = useState(false);
  const [payrollData, setPayrollData] = useState();
  const [showLoginMessage, setShowLoginMessage] = useState(false);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const payrolls_to_approve = queryParams.get('new_payroll_ids')?.split(',');

    if (payrolls_to_approve) {
      const payrollRowsParam = payrolls_to_approve.join(',');
      const url = `${API_domain}markPayrollAsApproved?payrollRows=${payrollRowsParam}`;

      axios({
        method: 'get',
        url: url,
        auth: {
          username: auth.email,
          password: auth.token,
        },
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then((response) => {
          // console.log('Response Dat message:', response.data.message);
          if (
            response.data.message === 'Payroll marked as approved successfully'
          ) {
            setPayrollData(response.data.payroll_data); // Assuming `payroll_data` contains the payroll data array
            setUpdateSuccess(true);
          } else if (response.data.message === 'No payrolls to approve') {
            setUpdateSuccess(true);
            message.success('No hay planillas por aprobar');
          }
        })
        .catch((error) => {
          console.error('Error:', error);
          if (error.response) {
            // setPayrollData(false);
            console.error('Error Response Data:', error.response.data);
          }
        });
    }
  }, [location, API_domain, auth]);

  // console.log('payrollData', payrollData);

  if (updateSuccess && payrollData) {
    return (
      <Content
        style={{
          margin: '0 0 0',
          overflow: 'initial',
          borderLeft: 'solid rgb(235,235,235) 1px',
          borderRight: 'solid rgb(235,235,235) 1px',
          borderTop: 'solid rgb(235,235,235) 1px',
        }}
      >
        <AffixDiv>
          <CheckCircleOutlined style={{ color: 'green', fontSize: '48px' }} />
          <Title level={2}>Gracias por aprobar la planilla</Title>
          <h3 style={{ marginBottom: 40 }}>
            Te estamos enviando la planilla aprobada y los comprobantes por
            correo.
          </h3>
          <ExistingPayrollTableContainer
            selectedPayroll={{
              approved_payroll_id: payrollData[0].approved_payroll_id,
              payable_date: payrollData[0].payable_date,
            }}
            selectedAccountingClientId={payrollData[0].accounting_clients_id}
            API_domain={API_domain}
            automaticallySendEmailBoolean={true}
            isCondensed={true}
          />
        </AffixDiv>
      </Content>
    );
  }
  setTimeout(() => {
    setShowLoginMessage(true);
  }, 2000);
  if (updateSuccess) {
    return (
      <Content
        style={{
          margin: '0 0 0',
          overflow: 'initial',
          borderLeft: 'solid rgb(235,235,235) 1px',
          borderRight: 'solid rgb(235,235,235) 1px',
          borderTop: 'solid rgb(235,235,235) 1px',
        }}
      >
        <AffixDiv>
          <CheckCircleOutlined
            style={{ color: 'green', fontSize: '48px', marginTop: 80 }}
          />
          <Title level={2}>Gracias</Title>
          <h3 style={{ marginBottom: 40 }}>No hay planillas por aprobar.</h3>
        </AffixDiv>
      </Content>
    );
  }

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Title level={2}>{antIcon}</Title>
        {showLoginMessage && (
          <>
            <div>
              Recuerda que debes estar <i>logged in</i> en este dispositivo.
            </div>
            <div style={{ paddingTop: 4 }}>
              En caso no lo estés, puedes iniciar sesión aquí:
            </div>
            <Button
              type='primary'
              href='/login'
              style={{ color: 'white', marginTop: 12 }}
            >
              Login
            </Button>
          </>
        )}
      </div>
    </div>
  );
};
const AffixDiv = styled.div`
  background-color: white;
  padding-left: 14px;
  padding-right: 14px;
  padding-top: 14px;
  padding-bottom: 8px;
  max-width: 1400px;
  margin: auto;
`;
const antIcon = <LoadingOutlined style={{ fontSize: 50 }} spin />;
export { PayrollApprovalView };
