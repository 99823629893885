import React, { createContext, useState } from 'react';
import Cookies from 'universal-cookie';

const authContext = createContext();

function useProvideAuth() {
  const cookies = new Cookies();
  const [email, setEmail] = useState(cookies.get('email'));
  const [token, setToken] = useState(cookies.get('token'));
  const [accounts, setAccounts] = useState(
    cookies.get('accounts') ? cookies.get('accounts') : []
  );
  const [adminEmail, setAdminEmail] = useState(cookies.get('adminEmail'));
  const [adminToken, setAdminToken] = useState(cookies.get('adminToken'));
  const [showAccountingFeatures, setShowAccountingFeatures] = useState(
    cookies.get('showAccountingFeatures')
  );
  // let initialAccounts = [];
  // try {
  //   const accountsCookie = cookies.get('accounts');
  //   console.log("Raw accounts cookie:", accountsCookie);
  //   if (accountsCookie) {
  //     initialAccounts = JSON.parse(decodeURIComponent(accountsCookie));
  //     console.log("Parsed accounts:", initialAccounts);
  //   }
  // } catch (e) {
  //   console.error("Error parsing accounts from cookies", e);
  //   initialAccounts = [];
  // }

  // const [accounts, setAccounts] = useState(initialAccounts);

  const signin = (cb) => {
    const cookies = new Cookies();
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let newEmail = params.get('email');
    let newToken = params.get('token');
    let showAccountingFeatures = params.get('showAccountingFeatures', false);

    if (newEmail && newToken) {
      cookies.set('email', newEmail, { maxAge: 60 * 60 * 24 * 365, path: '/' });
      cookies.set('token', newToken, { maxAge: 60 * 60 * 24 * 365, path: '/' });
      cookies.set('showAccountingFeatures', showAccountingFeatures, {
        maxAge: 60 * 60 * 24 * 365,
        path: '/',
      });
      let accountExists = accounts.some(
        (account) => account.email === newEmail
      );
      if (!accountExists) {
        let newAccounts = [
          ...accounts,
          {
            email: newEmail,
            token: newToken,
            showAccountingFeatures: showAccountingFeatures,
          },
        ];
        setAccounts(newAccounts);
        cookies.set('accounts', JSON.stringify(newAccounts), {
          maxAge: 60 * 60 * 24 * 365,
          path: '/',
        });
      }
      setEmail(newEmail);
      setToken(newToken);
      setShowAccountingFeatures(showAccountingFeatures);

      if (
        newEmail === 'noreplyherofacturas@gmail.com' ||
        newEmail === 'jarol@herofacturas.com'
      ) {
        cookies.set('adminEmail', newEmail, {
          maxAge: 60 * 60 * 24 * 365,
          path: '/',
        });
        cookies.set('adminToken', newToken, {
          maxAge: 60 * 60 * 24 * 365,
          path: '/',
        });
        setAdminEmail(newEmail);
        setAdminToken(newToken);
      }
    }

    cb();
  };

  const signout = () => {
    const emailToRemove = email;
    const updatedAccounts = accounts.filter(
      (account) => account.email !== emailToRemove
    );
    if (email === emailToRemove) {
      if (updatedAccounts.length > 0) {
        setEmail(updatedAccounts[0].email);
        setToken(updatedAccounts[0].token);
        setShowAccountingFeatures(updatedAccounts[0].showAccountingFeatures);
        cookies.set('email', updatedAccounts[0].email, {
          maxAge: 60 * 60 * 24 * 365,
          path: '/',
        });
        cookies.set('token', updatedAccounts[0].token, {
          maxAge: 60 * 60 * 24 * 365,
          path: '/',
        });
        cookies.set(
          'showAccountingFeatures',
          updatedAccounts[0].showAccountingFeatures,
          {
            maxAge: 60 * 60 * 24 * 365,
          }
        );
      } else {
        setEmail(null);
        setToken(null);
        setShowAccountingFeatures(null);
        cookies.remove('email', { path: '/' });
        cookies.remove('token', { path: '/' });
        cookies.remove('showAccountingFeatures', { path: '/' });
      }
    }
    setAccounts(updatedAccounts);
    if (updatedAccounts.length > 0) {
      cookies.set('accounts', JSON.stringify(updatedAccounts), {
        maxAge: 60 * 60 * 24 * 365,
        path: '/',
      });
    } else {
      cookies.remove('accounts', { path: '/' });
    }

    const currentAdminEmail = cookies.get('adminEmail');
    const currentAdminToken = cookies.get('adminToken');

    if (email === currentAdminEmail && token === currentAdminToken) {
      cookies.remove('adminEmail', { path: '/' });
      cookies.remove('adminToken', { path: '/' });
      setAdminEmail(null);
      setAdminToken(null);
    }
  };

  const switchAccount = (emailToSwitch) => {
    const accountToSwitch = accounts.find(
      (account) => account.email === emailToSwitch
    );
    if (accountToSwitch) {
      setEmail(accountToSwitch.email);
      setToken(accountToSwitch.token);
      setShowAccountingFeatures(accountToSwitch.showAccountingFeatures);
      cookies.remove('showAccountingFeatures', { path: '/' });
      cookies.set('email', accountToSwitch.email, {
        maxAge: 60 * 60 * 24 * 365,
        path: '/',
      });
      cookies.set('token', accountToSwitch.token, {
        maxAge: 60 * 60 * 24 * 365,
        path: '/',
      });
      if (accountToSwitch.showAccountingFeatures) {
        cookies.set(
          'showAccountingFeatures',
          accountToSwitch.showAccountingFeatures,
          {
            maxAge: 60 * 60 * 24 * 365,
          }
        );
      }
      if (
        accountToSwitch.email === 'noreplyherofacturas@gmail.com' ||
        accountToSwitch.email === 'jarol@herofacturas.com'
      ) {
        cookies.set('adminEmail', accountToSwitch.email, {
          maxAge: 60 * 60 * 24 * 365,
          path: '/',
        });
        cookies.set('adminToken', accountToSwitch.token, {
          maxAge: 60 * 60 * 24 * 365,
          path: '/',
        });
        setAdminEmail(accountToSwitch.email);
        setAdminToken(accountToSwitch.token);
      }
    }
    window.location.reload();
  };

  return {
    email,
    token,
    accounts,
    adminEmail,
    adminToken,
    showAccountingFeatures,
    signin,
    signout,
    switchAccount,
  };
}

function ProvideAuth({ children }) {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

export { authContext, ProvideAuth };
