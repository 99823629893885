import { LoadingOutlined, UploadOutlined } from '@ant-design/icons';
import { Button, Form, Input, Modal, Spin, Upload, message } from 'antd';
import axios from 'axios';
import { useContext, useState } from 'react';
import styled from 'styled-components/macro';
import { authContext } from '../../ProvideAuth.js';
import { read, utils } from 'xlsx';
import {
  downloadBlob,
  get_zoho_manual_entries_from_csv_blob,
  prepareZohoPayload,
} from '../../utils.js';

const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
};

const excelDateToJSDate = (serial) => {
  const epoch = new Date(1899, 11, 31);
  const excelDate = new Date(epoch.getTime() + (serial - 1) * 86400000);
  return excelDate;
};

const formatDate = (date) => {
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0'); //January is 0!
  const year = date.getFullYear().toString().substr(-2);
  // "2023-08-31"
  return '20' + year + '-' + month + '-' + day;
};

function CsvUploader(props) {
  const [files, setFiles] = useState([]);

  function isExcelDate(num) {
    return num > 44000 && num < 46000;
  }

  function isDateStringFormat(str) {
    return /^(\d{1,2})\/(\d{1,2})\/(\d{4})$/.test(str);
  }

  // Function to determine which column most likely contains dates
  function getDateColumn(jsonData) {
    let potentialDateColumns = {};
    for (let row of jsonData) {
      for (let [index, cell] of row.entries()) {
        if (isExcelDate(cell) || isDateStringFormat(cell)) {
          potentialDateColumns[index] = (potentialDateColumns[index] || 0) + 1;
        }
      }
    }

    let maxCount = 0;
    let dateColumn = -1;
    for (let [index, count] of Object.entries(potentialDateColumns)) {
      if (count > maxCount) {
        maxCount = count;
        dateColumn = parseInt(index, 10);
      }
    }
    return dateColumn;
  }

  function getMostCommonMonth(columnData) {
    let monthCounts = {};

    for (let cell of columnData) {
      if (typeof cell === 'number' && isExcelDate(cell)) {
        const date = excelDateToJSDate(cell);
        const month = date.getMonth() + 1; // Months are 0-indexed
        monthCounts[month] = (monthCounts[month] || 0) + 1;
      } else if (typeof cell === 'string' && isDateStringFormat(cell)) {
        const [first, second] = cell.split('/').map(Number);
        if (first <= 12) {
          monthCounts[first] = (monthCounts[first] || 0) + 1;
        }
        if (second <= 12) {
          monthCounts[second] = (monthCounts[second] || 0) + 1;
        }
      }
    }

    let commonMonth = null;
    let maxCount = 0;
    for (let [month, count] of Object.entries(monthCounts)) {
      if (count > maxCount) {
        maxCount = count;
        commonMonth = parseInt(month, 10);
      }
    }
    return commonMonth;
  }

  const uploadChanged = (event) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      if (!e.target || !e.target.result) return;
      const data = new Uint8Array(e.target.result);
      const workbook = read(data, { type: 'array' });

      // Process the workbook data as needed
      const worksheet = workbook.Sheets[workbook.SheetNames[0]];
      var jsonData = utils.sheet_to_json(worksheet, { header: 1 });

      const dateColumn = getDateColumn(jsonData);
      const dateColumnData = jsonData.map((row) => row[dateColumn]);
      const commonMonth = getMostCommonMonth(dateColumnData);

      jsonData = jsonData.map((row) => {
        return row.map((cell, index) => {
          if (index === dateColumn) {
            if (typeof cell === 'number' && isExcelDate(cell)) {
              const date = excelDateToJSDate(cell);
              return formatDate(date);
            } else if (typeof cell === 'string' && isDateStringFormat(cell)) {
              let [first, second, year] = cell.split('/').map(Number);
              if (first <= 12 && second <= 12) {
                // Ambiguous date, choose the part that matches the most common month
                if (first === commonMonth) {
                  // Interpret as mm/dd/yyyy
                } else if (second === commonMonth) {
                  // Interpret as dd/mm/yyyy
                  [first, second] = [second, first];
                }
              }
              const date = new Date(year, first - 1, second);
              return formatDate(date);
            }
          }
          return cell;
        });
      });

      props.setCsvData(jsonData);
    };
    reader.readAsArrayBuffer(event.file.originFileObj);
  };

  const fileRemoved = (event) => {
    const filteredFiles = files.filter((file) => file !== event);
    setFiles(filteredFiles);
  };

  return (
    <Upload
      name='file'
      showUploadList={{ showRemoveIcon: true }}
      accept='.xls, .xlsx, .csv'
      onChange={(e) => uploadChanged(e)}
      onRemove={(e) => fileRemoved(e)}
      // capture={undefined}
    >
      <Button icon={<UploadOutlined />}>Click to Upload</Button>
    </Upload>
  );
}

function AdminZohoJournalPoster(props) {
  const [isSubmitting, setSubmitting] = useState(false);
  const auth = useContext(authContext);
  const [form] = Form.useForm();
  const [visible, setVisible] = useState(false);
  const [csvData, setCsvData] = useState([]);
  const [team, setTeam] = useState(null);

  const showModal = () => {
    setVisible(true);
  };

  const handleOk = () => {
    setVisible(false);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const onFinish = (values) => {
    // use the csvData to call util function to convert to zoho object
    console.log(csvData);
    const zohoObject = prepareZohoPayload(csvData);
    // call zohoCreateJournal endpoints with payload, org_id, and auth
    const data = {
      zohoObject: zohoObject,
      org_id: '817700523',
    };
    axios({
      method: 'post',
      url: props.API_domain + 'zohoCreateJournal',
      data: data,
      auth: {
        username: auth.email,
        password: auth.token,
      },
    })
      .then((response) => {
        handleOk();
        setTimeout(() => setSubmitting(false), 2000);
      })
      .catch(function (response) {
        alert('No tienes este permiso habilitado');
        setSubmitting(false);
      });
    // // Convert the object to a string in a copy-pasteable format
    // const zohoObjectString = JSON.stringify(zohoObject, null, 2); // Using null and 2 for pretty printing

    // // Create a blob from the string
    // const zohoObjectBlob = new Blob([zohoObjectString], { type: 'text/plain' });

    // // Download the blob as a .txt file
    // downloadBlob(zohoObjectBlob, 'zohoObject.txt');
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const onTeamSelect = (team) => {
    setTeam(team);
  };

  return (
    <>
      <Button type='secondary' onClick={showModal}>
        <ButtonSpan>JOURNAL POSTER a ZOHO</ButtonSpan>
      </Button>
      <Modal
        title='Convertidor a ZOHO'
        visible={visible}
        footer={
          <Button
            type='primary'
            htmlType='submit'
            block
            style={{
              fontWeight: 500,
              marginTop: 10,
              marginBottom: 10,
            }}
            onClick={onFinish}
          >
            {isSubmitting ? <Spin indicator={antIcon} /> : <>Convertir</>}
          </Button>
        }
        onCancel={handleCancel}
        centered
      >
        <Form
          {...layout}
          name='basic'
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          form={form}
        >
          {/* <Form.Item
            name="finca"
            label="Finca:"
            rules={[
              {
                required: true,
                message: "Por favor escriba el nombre de la finca",
              },
            ]}
          > */}
          <br></br>
          <br></br>
          <span>
            COLUMNAS: 1. fecha 2. credit_account 3. debit_account 4. notas 5.
            monto 6. itbms{' '}
          </span>
          <br></br>
          <br></br>
          <CsvUploader setCsvData={setCsvData} />

          {/* </Form.Item> */}
        </Form>
      </Modal>
    </>
  );
}

const ButtonSpan = styled.span`
  font-weight: 500;
`;

const antIcon = <LoadingOutlined spin style={{ color: 'white' }} />;

export { AdminZohoJournalPoster };
