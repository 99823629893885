import {
  BookFilled,
  SnippetsFilled,
  BugFilled,
  ContactsFilled,
  BankFilled,
  FolderFilled,
  PieChartFilled,
  SettingFilled,
  FlagFilled,
  InteractionOutlined,
  WalletFilled,
  CreditCardOutlined,
  LogoutOutlined,
} from '@ant-design/icons';
import { Layout, Menu, Select, Tooltip } from 'antd';
import React, { useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { ShareHeroButton } from './ShareHeroButton.js';
// import { SignoutButton } from './SignoutButton.js';
import styled from 'styled-components/macro';
import { authContext } from '../ProvideAuth.js';
import { AdminGetAccountingClientUserLoginLink } from './Admin/AdminGetAccountingClientUserLoginLink';

const { Sider } = Layout;
const { Option } = Select;

const adminEmails = [
  'noreplyherofacturas@gmail.com',
  'jarol@herofacturas.com',
  'miguel@herofacturas.com',
];

const accountingEmails = {
  'noreplyherofacturas@gmail.com': 100,
  'jarol@herofacturas.com': 100,
  'ernestolyons@gmail.com': 200,
  'salomon.cohen.08@gmail.com': 225,
  'gtesta@gutpanama.com': 245,
  'alex@fonduee.com': 135,
  'javier@fonseca.com': 135,
  'joseralemanc@gmail.com': 200,
  'kalabazapa@gmail.com': 200,
  'maxalfredoga@gmail.com': 200,
  'rocioroldan5@gmail.com': 430,
  'gretelsucre@gmail.com': 430,
  'felix.weng@dalifex.com': 200,
  'milagros.esquivel@dalifex.com': 200,
  'nathalie.rodriguez@dalifex.com': 200,
  'elisayanes@gmail.com': 100,
  'antonio@lagunazo.com': 100,
  'aheilbron@ptmpanama.com': 50,
  'jorge4arias@gmail.com': 175,
  'vale@rpfoodgroup.com': 180,
  'rafael@colrizado.com': 255,
  'a.saenz.u@gmail.com': 255,
  'fguillen@herofacturas.com': 100,
  'invernaderocomplex@gmail.com': 245,
  'contabilidad@celtecgroup.com': 0,
  'd.torres@myenergylatam.com': 0,
  'admin@celtecgroup.com': 0,
  'recepcion@celtecgroup.com': 0,
  'cookingmammapty@gmail.com': 0,
  'guillemarria@gmail.com': 375,
  'miguel@herofacturas.com': 100,
  'gtesta+invernadero@gutpanama.com': 245,
  'rdmm1893@gmail.com': 125,
  'micaelalmanza@gmail.com': 275,
  'autoparteshk24@gmail.com': 275,
  'ivangonzaleza23@gmail.com': 415,
  'gary.martin@quantil.com.co': 180,
  'asistencia@flashcocinas.com': 150,
  'mark@flashcocinas.com': 200,
  'jdguerrac04@gmail.com': 150,
  'jbrawerman@hotmail.com': 150,
  'nutricion@malegiraldo.com': 200,
  'nutricion@dioteiza.com': 200,
  'nutrition@helenrd.com': 200,
  'milaymimopty2@gmail.com': 200,
  'milaymimopty@gmail.com': 200,
};

function isPrime(num) {
  if (num <= 1) return false;
  if (num <= 3) return true;

  if (num % 2 === 0 || num % 3 === 0) return false;

  for (let i = 5; i * i <= num; i += 6) {
    if (num % i === 0 || num % (i + 2) === 0) return false;
  }
  return true;
}

function dayOfYear(date) {
  const start = new Date(date.getFullYear(), 0, 0);
  const diff = date - start;
  const oneDay = 1000 * 60 * 60 * 24;
  return Math.floor(diff / oneDay);
}

const today = new Date();
const todayOfYear = dayOfYear(today);

const HomeSider = (props) => {
  const auth = useContext(authContext);
  const location = useLocation();
  const siderDefaultKey_map = {
    '/expenses': ['1'],
    '/reports': ['2'],
    '/cards': ['3'],
    '/visualizations': ['4'],
    '/config': ['5'],
    // '/compras': ['6'],
    '/ordenes': ['6'],
    '/admin': ['7'],
    '/help': ['8'],
    '/dashboard': ['9'],
    '/requisitions': ['10'],
    '/recibidos': ['11'],
    '/invoices': ['12'],
    '/quotes': ['13'],
    '/reconciliation': ['14'],
    '/accounting': ['15'],
    '/bank': ['16'],
    '/accountingInbox': ['17'],
    '/transactions': ['18'],
    '/conciliation': ['19'],
    '/hr': ['20'],
  };
  var siderDefaultKey = siderDefaultKey_map[location.pathname];

  const isAccountingEmail = (email) =>
    email in accountingEmails || auth.showAccountingFeatures;

  let reloadIfSameUrl = (path) => {
    if (path == window.location.pathname) {
      window.location.reload();
    }
  };

  return (
    <Sider
      style={{
        overflow: 'auto',
        overflowX: 'hidden',
        height: '100vh',
        position: 'fixed',
        left: 0,
      }}
      collapsedWidth='60'
      breakpoint='sm'
    >
      <Link
        to='/expenses'
        onClick={() => {
          reloadIfSameUrl('/expenses');
          siderDefaultKey = ['1'];
        }}
      >
        <>
          {props.mobile ? (
            <Logo
              src='heroLogoTransparent.png'
              style={{ width: '32px', height: '32px' }}
            />
          ) : (
            <Logo
              src='heroLogoTransparent.png'
              style={{ width: '80px', height: '80px' }}
            />
          )}
        </>
      </Link>
      <Menu
        mode='inline'
        selectedKeys={siderDefaultKey}
        style={{ backgroundColor: 'rgb(247,249,252)', borderRight: '0px' }}
      >
        {isAccountingEmail(auth.email) && !props.mobile ? (
          <Menu.Item
            key='17'
            icon={
              <FlagFilled
                style={{
                  fontSize: 'max(1.05em,14px)',
                  marginRight: 15,
                  color: 'rgb(100,100,100)',
                }}
              />
            }
            style={{
              marginTop: 8,
              marginBottom: 5,
              fontSize: 'max(1.16em,14px)',
            }}
          >
            <span>Inbox</span>
            <Link to='/accountingInbox' />
          </Menu.Item>
        ) : (
          <></>
        )}
        <Menu.Item
          key='1'
          icon={
            <BookFilled
              style={{
                fontSize: 'max(1.05em,14px)',
                marginRight: 15,
                color: 'rgb(100,100,100)',
              }}
            />
          }
          style={{
            marginTop: 8,
            marginBottom: 5,
            fontSize: 'max(1.16em,14px)',
          }}
        >
          <span className='firstStep'>Gastos</span>
          <Link to='/expenses' onClick={() => reloadIfSameUrl('/expenses')} />
        </Menu.Item>
        <Menu.Item
          key='3'
          icon={
            <WalletFilled
              style={{
                fontSize: 'max(1.05em,14px)',
                marginRight: 15,
                color: 'rgb(100,100,100)',
              }}
            />
          }
          style={{
            marginTop: 8,
            marginBottom: 5,
            fontSize: 'max(1.16em,14px)',
          }}
        >
          <span>Tarjetas</span>
          <Link to='/cards' />
        </Menu.Item>
        {location.pathname === '/cards' ||
        location.pathname === '/transactions' ||
        location.pathname === '/conciliation' ? (
          <>
            <Menu.Item
              key='18'
              icon={
                <CreditCardOutlined
                  style={{
                    fontSize: 'max(1.05em,14px)',
                    marginRight: 10,
                    color: 'rgb(100,100,100)',
                  }}
                />
              }
              style={{
                marginTop: 8,
                marginBottom: 5,
                fontSize: 'max(1.16em,14px)',
                paddingLeft: '40px', // Indent to show as a child
              }}
            >
              <span className='firstStep'>Transacciones</span>
              <Link
                to='/transactions'
                onClick={() => reloadIfSameUrl('/transactions')}
              />
            </Menu.Item>
            <Menu.Item
              key='19'
              icon={
                <InteractionOutlined
                  style={{
                    fontSize: 'max(1.05em,14px)',
                    marginRight: 10,
                    color: 'rgb(100,100,100)',
                  }}
                />
              }
              style={{
                marginTop: 8,
                marginBottom: 5,
                fontSize: 'max(1.16em,14px)',
                paddingLeft: '40px', // Indent to show as a child
              }}
            >
              <span className='firstStep'>Conciliación</span>
              <Link
                to='/conciliation'
                onClick={() => reloadIfSameUrl('/conciliation')}
              />
            </Menu.Item>
          </>
        ) : (
          ''
        )}
        <Menu.Item
          key='2'
          icon={
            <FolderFilled
              style={{
                fontSize: 'max(1.05em,14px)',
                marginRight: 15,
                color: 'rgb(100,100,100)',
              }}
            />
          }
          style={{
            marginTop: 8,
            marginBottom: 5,
            fontSize: 'max(1.16em,14px)',
          }}
        >
          <span className='thirdStep'>Reportes</span>
          <Link to='/reports' onClick={() => reloadIfSameUrl('/reports')} />
        </Menu.Item>
        {props.mobile ? (
          <></>
        ) : (
          <>
            <Menu.Item
              key='4'
              icon={
                <PieChartFilled
                  style={{
                    fontSize: 'max(1.05em,14px)',
                    marginRight: 15,
                    color: 'rgb(100,100,100)',
                  }}
                />
              }
              style={{
                marginTop: 8,
                marginBottom: 5,
                fontSize: 'max(1.16em,14px)',
              }}
            >
              <span>Visualizaciones</span>
              <Link to='/visualizations' />
            </Menu.Item>
            {isAccountingEmail(auth.email) && !props.mobile && (
              <>
                <Menu.Item
                  key='15'
                  icon={
                    <SnippetsFilled
                      style={{
                        fontSize: 'max(1.05em,14px)',
                        marginRight: 15,
                        color: 'rgb(100,100,100)',
                      }}
                    />
                    // <UnorderedListOutlined
                    //   style={{ fontSize: 'max(1.05em,14px)', marginRight: 15, color:'rgb(100,100,100)' }}
                    // />
                  }
                  style={{
                    marginTop: 8,
                    marginBottom: 5,
                    fontSize: 'max(1.16em,14px)',
                  }}
                >
                  <span>Contabilidad</span>
                  <Link to='/accounting' />
                </Menu.Item>
                <Menu.Item
                  key='20'
                  icon={
                    <ContactsFilled
                      style={{
                        fontSize: 'max(1.05em,14px)',
                        marginRight: 15,
                        color: 'rgb(100,100,100)',
                      }}
                    />
                  }
                  style={{
                    marginTop: 8,
                    marginBottom: 5,
                    fontSize: 'max(1.16em,14px)',
                  }}
                >
                  <span>Planilla</span>
                  <Link to='/hr' />
                </Menu.Item>
              </>
            )}
          </>
        )}
        {props.mobile ? (
          <></>
        ) : (
          <Menu.Item
            key='5'
            icon={
              <SettingFilled
                style={{
                  fontSize: 'max(1.05em,14px)',
                  marginRight: 15,
                  color: 'rgb(100,100,100)',
                }}
              />
            }
            style={{
              marginTop: 30,
              marginBottom: 30,
              fontSize: 'max(1.16em,14px)',
            }}
          >
            <span className='fourthStep'>Configuración</span>
            <Link to='/config' />
          </Menu.Item>
        )}
        {adminEmails.includes(auth.email) ? (
          <>
            <Menu.Item
              key='16'
              icon={
                <BankFilled
                  style={{
                    fontSize: 'max(1.05em,14px)',
                    marginRight: 15,
                    color: 'rgb(100,100,100)',
                  }}
                />
              }
              style={{
                marginTop: 8,
                marginBottom: 5,
                fontSize: 'max(1.16em,14px)',
              }}
            >
              <span>Banco</span>
              <Link to='/bank' />
            </Menu.Item>
            {/* <Menu.Item
              key='15'
              icon={
                <SnippetsFilled
                style={{ fontSize: 'max(1.05em,14px)', marginRight: 15, color:'rgb(100,100,100)' }}
                />
              }
              style={{
                marginTop: 8,
                marginBottom: 5,
                fontSize: 'max(1.16em,14px)',
              }}
              >
              <span>Informes</span>
              <Link to='/accounting' />
            </Menu.Item> */}

            <Menu.Item
              key='7'
              icon={
                <BugFilled
                  style={{
                    fontSize: 'max(1.05em,14px)',
                    marginRight: 15,
                    color: 'rgb(100,100,100)',
                  }}
                />
              }
              style={{
                marginTop: 8,
                marginBottom: 5,
                fontSize: 'max(1.16em,14px)',
              }}
            >
              <span>Admin</span>
              <Link to='/adminDashboard' />
            </Menu.Item>
          </>
        ) : (
          <></>
        )}
        <Menu.Item
          key='8'
          style={{
            marginTop: 8,
            marginBottom: 5,
            fontSize: 'max(1.16em,14px)',
          }}
        >
          {isPrime(todayOfYear) &&
            isAccountingEmail(auth.email) &&
            accountingEmails[auth.email] > 0 && (
              <ShareHeroButton
                API_domain={props.API_domain}
                mobile={props.mobile}
                amount={accountingEmails[auth.email]}
              />
            )}
        </Menu.Item>
        {auth.adminEmail && adminEmails.includes(auth.adminEmail) && (
          <Menu.Item
            style={{
              zIndex: 1, // Ensures this item appears above the other
              position: 'absolute',
              width: '100%',
              bottom: '40px',
              backgroundColor: 'rgb(247,249,252)',
              borderTop: '1px solid #f0f0f0',
            }}
          >
            <div style={{ width: 'calc(100% - 30px)', marginTop: 8 }}>
              <AdminGetAccountingClientUserLoginLink
                API_domain={props.API_domain}
              />
            </div>
          </Menu.Item>
        )}
        <Menu.Item
          style={{
            position: 'absolute',
            zIndex: 1,
            width: '100%',
            marginBottom: 0,
            bottom: 4,
            backgroundColor: 'rgb(247,249,252)',
            borderTop: '1px solid #f0f0f0',
          }}
        >
          {auth.accounts.length > 1 && (
            <Select
              showSearch
              defaultValue={auth.email}
              style={{ width: 'calc(100% - 30px)', marginTop: 8 }}
              onChange={(value) => auth.switchAccount(value)}
              placement='topLeft'
              dropdownMatchSelectWidth={false}
            >
              {auth.accounts.map((account) => (
                <Option key={account.email} value={account.email}>
                  {account.email}
                </Option>
              ))}
            </Select>
          )}
          <Tooltip title={`Logout ${auth.email}`}>
            <LogoutOutlined
              onClick={() => auth.signout(auth.email)}
              style={{
                fontSize: '20px',
                color: 'rgb(100,100,100)',
                cursor: 'pointer',
                marginLeft: auth.accounts.length > 1 ? '10px' : '0',
                marginTop: 8,
              }}
            />
          </Tooltip>
        </Menu.Item>
      </Menu>
    </Sider>
  );
};

const Logo = styled.img`
  margin-top: 16px;
  margin-bottom: 20px;
  cursor: pointer;
`;

export { HomeSider };
